import React from "react";

const blogsData = [
  {
    link: "/blog/net-core-jwt-tdd",
    img: "JWT-blog-image.svg",
    title: "Generating Json Web Tokens with .Net Core: A TDD Approach",
    titleDe: "Generieren von Json-Web-Tokens mit .Net Core: Ein TDD-Ansatz",
    desc: "A test driven approach for creting secure Json Web Tokens in a back-end .NET Core web API project",
    descDe: "Ein testgetriebener Ansatz zum Erstellen sicherer Json-Web-Tokens in einem Back-End-.NET Core-Web-API-Projekt",
    date: "20th July, 2023",
    dateDe: "20. Juli, 2023",
    
    initialContent: (<div></div>)    
    ,
    content: (<div class="content-item">

        <h3>What are Json Web Tokens?</h3><p></p><p>JSON Web Tokens (JWTs) are secure, compact data structures used for the safe transmission of information between parties as JSON objects. This information is trusted because it's digitally signed, typically with a secret or a public/private key pair. A JWT is divided into three parts: the header (identifies token type and algorithm), payload (contains user and metadata "claims"), and signature (verifies sender and protects against tampering). JWTs are commonly used for user authentication and authorization in web applications, allowing secure access to services and resources.</p>
        <p>In the example application we'll be exploring in this blog, we'll emulate the backend process that occurs after a user has successfully logged in. Specifically, we'll focus on how a JWT is generated using a symmetric key and returned to the user. This token serves as proof of authentication, allowing the user to access protected resources without the need for maintaining a traditional session on the server. For the purposes of this sample application, we're opting for a symmetric key approach, though in real-world scenarios, more complex key structures might be employed.</p>
      <p></p><h1></h1>
      <h3>Lets Go!</h3>
      <p>This complete project will be available on my GitHub via the following link:  However, if you'd like to follow along via my instructions, be my guest. </p><p></p>
      <ol><li>In Visual Studio, create a empty web API project. </li><li>Next, add a new test project to the root solution of the project. In this blog I am using the xUnit framework. </li><li>Once the test project is added, don't forget to reference the original web API project created in the first step.</li>
      </ol><p></p>
    <p></p>
    <h3>Token generation method unit test </h3>
   
<p> Before we start writing out the unit test generating the JWT, it's important to outline and plan what the concrete implementation intends to do so that we can more easily write our test. 
The generation of the token solely relies on being created via the token handler wrapper class, which will be called via a method in the repository class. A wrapper class is a custom object designed to encapsulate or "wrap" another object, often from a third-party library, an external system, or built-in system classes. Its purpose is to provide a simplified or more suitable interface, extend or limit functionality, or offer a way to adapt or modify the behaviour of the wrapped object. Class wrappers are perfect for TDD as third party services can easily be mocked via its wrapped class. We will discuss mocking more in just a moment. We will now make a start on writing the unit test.</p>
      <p>In line with the TDD approach, our initial test will be neutral since it's empty. But as we enhance and refine our test, we'll develop the handler wrapper class alongside it. This iterative process will see our tests fluctuating between passing and failing states:</p>
      <div class="code-block"><pre><code class="language-csharp">{`public class JWTRepositoryTests
{
    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {

    }
}
`}</code></pre></div>

<p>Now that we have the blank test setup, we are going to introduce a mock of our JWT handler wrapper class using the Moq framework. But what exactly is mocking?</p><p></p><p>Mocking in TDD provides a way to isolate units of code, ensuring tests focus solely on the target functionality without being influenced by external dependencies. By using mocks, tests run faster, free from the latencies of real-world operations like database or network interactions. Mocks also grant control, allowing developers to simulate specific behaviors, including edge cases and error conditions, without any real-world side effects. This controlled environment ensures that tests accurately evaluate code behavior under a variety of scenarios, ensuring robustness and reliability.</p><p></p>
<p>In this context, we're mocking the JWT Handler wrapper class to ensure that when we test a method relying on the JWT Handler wrapper, we're evaluating only the logic of that method, not the inner workings of the JWT Handler concrete class itself. This distinction guarantees that any test outcome is attributed solely to the method we're assessing, not any unexpected behaviour from the JWT Handler:</p>
<div class="code-block"><pre><code class="language-csharp">{`public class JWTRepositoryTests
{
    private readonly Mock<IJwtHandlerWrapper> _jwtHandlerMock;
    
    public JWTRepositoryTests()
    {
        _jwtHandlerMock = new Mock<IJwtHandlerWrapper>();
    }

    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {
        //Arrange
        _jwtTokenHandlerMock.Setup(th =&gt; th.WriteToken(It.IsAny&lt;SecurityToken())).Returns("fakeTokenString");
    }
}
`}</code></pre></div>
<p>At this stage, we've only set up the mock behavior for the WriteToken method, but haven't included an assertion in our test. This is intentional. As we proceed, our test will rely on a method from our repository class that interacts with the JWT handler wrapper. Once we introduce this method into our tests, we'll add the necessary assertions to validate its behavior in conjunction with the JWT handler.
  </p> 
  <p>The next step in our journey will be to implement the actual IJwtTokenHandler interface, class & method that adheres to it:</p>    
  <div class="code-block"><pre><code class="language-csharp">{`public class JWTHandlerWrapper : IJwtHandlerWrapper
{
    private readonly JwtSecurityTokenHandler _tokenHandler;
    
    public JWTHandlerWrapper()
    {
        _tokenHandler = new JwtSecurityTokenHandler();
    }
    
    public string WriteToken(SecurityToken token)
    {
        return _tokenHandler.WriteToken(token);
    }
}
`}</code></pre></div>
<div class="code-block"><pre><code class="language-csharp">{`public interface IJwtHandlerWrapper
{
    string WriteToken(SecurityToken token);
}
`}</code></pre></div>
<p>Having implemented the IJwtTokenHandler, our attention now shifts to the repository class. This class will feature a method dependent on the JWT handler wrapper for token generation, necessitating the inclusion of the wrapper class as a dependency. Moreover, to access the application's configuration properties, another dependency will be required, specifically for reading the secret symmetric key from the appsettings.json file. Although storing a symmetric key directly in the configuration is not a best practice for production apps, the underlying concept can be adapted to reference a URL (such as Azure Key Vault) where the symmetric key can be fetched securely. With the repository's arrangement in place, 
our next step in the TDD process is to update the unit test. This will involve the Arrange sequence where we set up our prerequisites, followed by the Act sequence, where we'll invoke the CreateToken method in the repository. Finally, we'll ensure our test covers the necessary assertions for the JWT generation method:</p>
<div class="code-block"><pre><code class="language-csharp">{`public class JWTRepositoryTests
{

    private readonly Mock<IJwtHandlerWrapper> _jwtHandlerMock;
    private readonly Mock<IConfiguration> _configurationMock;
    private readonly User _user;

    public JWTRepositoryTests()
    {
        _jwtHandlerMock = new Mock<IJwtHandlerWrapper>();
        _configurationMock = new Mock<IConfiguration>();
        _user = new User { Username = "TestUsername", Password = "TestPassword", Role = "testRole" };

        // Setup mock configuration values
        _configurationMock.SetupGet(x => x["Jwt:Secret"]).Returns("your-secret-value");
        _configurationMock.SetupGet(x => x["Jwt:Issuer"]).Returns("your-issuer-value");
        _configurationMock.SetupGet(x => x["Jwt:Audience"]).Returns("your-issuer-value");
    }

    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {
        //Arrange
        _jwtHandlerMock.Setup(th =&gt; th.WriteToken(It.IsAny<SecurityToken>())).Returns("fakeTokenString");
        var repository = new JWTRepository(_configurationMock.Object, _jwtTokenHandlerMock.Object);

        //Act
        var token = repository.GenerateToken(_user);

        //Assert
        Assert.Equal("fakeTokenString", token);
    }
}
`}</code></pre></div>
<p>In constructor of this test class, we have created a test mock user object. This design choice enhances test maintainability; by initializing the user once in the constructor, we allow the same user instance to be shared across multiple tests, preventing redundancy. In the remainder of the test class constructor, we've also setup mock configuration values which mimics any settings that are present in the appsettings.json file. When using a mocked instance of IConfiguration, such settings in appsettings.json will not be read, so it's important we remember to create theese mocked values. 
Moving onto the unit test itself, the Act phase involves calling the `GenerateToken` method, using the mock user as input. This emphasizes how a token might be generated for a user upon successful authentication. To validate the correctness of our method's behavior, the Assert phase confirms that the returned token matches our expectations.
With this well-structured unit test, which leverages mocking to its advantage, we can now proceed to flesh out the concrete implementation of the repository class along with the user data strucutre class.</p>
<h3>Repository class, method & user model implementation</h3>
<div class="code-block"><pre><code class="language-csharp">{`public class JWTRepository
{
    private readonly IConfiguration _configuration;
    private readonly IJwtHandlerWrapper _jwtHandler;
    
    public JWTRepository(IConfiguration configuration, IJwtHandlerWrapper jwtHandler)
    {
        _configuration = configuration;
        _jwtHandler = jwtHandler;
    }
    
    public string GenerateToken(User user)
    {
        try
        {
            var securityKey = new SymmetricSecurityKey(Encoding.UTF8.GetBytes(_configuration["Jwt:Secret"]));
            var credentials = new SigningCredentials(securityKey, SecurityAlgorithms.HmacSha256);
            var claims = new[]
            {
                new Claim(ClaimTypes.NameIdentifier,user.Username),
                new Claim(ClaimTypes.Role,user.Role)
            };
            
            var token = new JwtSecurityToken(_configuration["Jwt:Issuer"],
              _configuration["Jwt:Audience"],
              claims,
              expires: DateTime.UtcNow.AddSeconds(30),
              signingCredentials: credentials);
              
            var tokenString = _jwtHandler.WriteToken(token);
            
            return tokenString;
        }
        
        catch(Exception ex)
        {
            var error = ex.ToString();
            return null;
        }
    }
}
`}</code></pre></div>
<div class="code-block"><pre><code class="language-csharp">{`public class User
{
    public string Username { get; set; }
    public string Password { get; set; }
    public string Role { get; set; }
}
`}</code></pre></div>
<p>That's it, we now have a passing unit test & the concrete implementation is also complete! The final functionality of the application is to create a controller endpoint with the purpose of authenticating a user. This controller method will call GenerateToken repository method and assign the token to the user upon successful authentication. Of course because this is a TDD approach, we will begin by writing out a failing unit test for the controller method & incrementally add to this unit test whilst we add to our concrete implementation of the controller method. Let us continue! </p>
<h3>Controller authentication end-point unit test</h3>
<p>Firstly, we will create a new class file under our test project and name it ControllerTests. This helps us keep our tests organised separating repository tests from controller tests as well as potential future integration tests. Before creating the unit test, we will discuss the desired behaviour of the concrete implementation of the controller test.</p>
<p> For demonstration purposes, the user's username and password will be passed to the controller' s method within the HTTP header. A pre-defined user will be set up in the appsettings.json file, so likewise to earlier, we will need to mock out these details along with IConfguration. Within this unit test, the repsiotry class will also need to be mocked out in order to assign the JWT to the user during authentication </p>
<p>To assess if the unit test passes or fails, we will be asserting if the controller method returns a status code of 200, meaning that the user has been successfully authenticated. Let's move onto the test implementation: </p>
<div class="code-block"><pre><code class="language-csharp">{`public class ControllerTests
{
    private Mock&lt;IConfiguration&gt; _mockConfiguration;
    private Mock&lt;IJWTRepository&gt; _mockJwtRepository;
    private readonly User _mockUser;
    
    public ControllerTests()
    {
        _mockConfiguration = new Mock<IConfiguration>();
        _mockJwtRepository = new Mock<IJWTRepository>();
        _mockUser = new User { Username = "TestUsername", Password = "TestPassword", Role = "testRole" };

        _mockConfiguration.SetupGet(x => x["TestUsers:Username"]).Returns("TestUsername");
        _mockConfiguration.SetupGet(x => x["TestUsers:Password"]).Returns("TestPassword");
    }
    
    [Fact] 
    public async Task AuthenticateUser_Returns_OK()
    {
        //Arrange
        var jwtReppsitoryMock = new Mock<IJWTRepository>();
        jwtReppsitoryMock.Setup(r => r.GenerateToken(_mockUser)).Returns("testToken");

        var controller = new JwtController(_mockConfiguration.Object, _mockJwtRepository.Object);
        controller.ControllerContext.HttpContext = new DefaultHttpContext();
        controller.HttpContext.Request.Headers["username"] = "TestUsername";
        controller.HttpContext.Request.Headers["password"] = "TestPassword";

        //Act
        var result = controller.AuthenticateUser();

        //Assert

        var objectResult = Assert.IsType<OkObjectResult>(result);
        Assert.Equal(200, objectResult.StatusCode);
    }
}
`}</code></pre></div>
<p>With the above implementation, once we create the controller class file, we should have a passing unit test for successfully authenticating the user whilst simultaneously generating a JWT. Let's move onto the concrete controller class. </p>
<h3>Controller class & authentication end-point method implementation</h3>
<div class="code-block"><pre><code class="language-csharp">{`[Route("api/[controller]")]
[ApiController]
public class JwtController : ControllerBase
{
    private readonly IJWTRepository _jwtRepository;
    private readonly IConfiguration _configuration;
    
    public JwtController(IConfiguration configuration, IJWTRepository jWTRepository)
    {
        _configuration = configuration;
        _jwtRepository = jWTRepository;
    }
    
    [HttpPost("/AuthenticateUser")]
    public IActionResult AuthenticateUser()
    {
        string httpHeaderUsername = Request.Headers["username"].FirstOrDefault();
        string httpHeaderPassword = Request.Headers["password"].FirstOrDefault();

        User user = new User
        {
            Username = httpHeaderUsername,
            Password = httpHeaderPassword,
            Role = "User"
        };
        try
        {
            string username = _configuration["TestUsers:Username"];
            string password = _configuration["TestUsers:Password"];

            if (httpHeaderUsername == username &amp;&amp; httpHeaderPassword == password)
            {
                var token = _jwtRepository.GenerateToken(user);
                return Ok(token);
            }
            else
            {
                return StatusCode(401, "Incorrect credentials");
            }
        }
        catch (Exception ex)
        {
            return StatusCode(500, ex.Message);
        }
    }
}
`}</code></pre></div>
<p>That's it! Now that the concrete implementation is complete, we can manually test out the user authentication along with the JWT generation using an API tool such as Postman. Set the URL to POST and add the localhost ULR along with the port your application is running on. You can pass in the username and password in the HTTP headers section. Upon successful authentication, you will get a 200 OK code response along with a token. See the below image: </p>
<img src="https://kmc-technologies.ltd/JWT-TDD-Blog-Postman.png"></img>
<p>Right now, this brings us to the end of this blog, but part 2 will be coming soon where will also test for unsuccessful operations when generating the JWT along with adding integration tests. Sit tight!  </p>
  <br></br>
  </div>
    ),
    //***************************************** End EN Content ********************************************************** */

    contentDe: (<div className="content-item">
      <p></p><h3>Was sind Json-Web-Tokens?</h3>
      <p>JSON Web Tokens (JWTs) sind sichere, kompakte Datenstrukturen, die für die sichere Übertragung von Informationen zwischen Parteien als JSON-Objekte verwendet werden. Diese Informationen sind vertrauenswürdig, da sie digital signiert sind, normalerweise mit einem Geheimnis oder einem öffentlichen/privaten Schlüsselpaar. Ein JWT ist in drei Teile unterteilt: den Header (identifiziert den Token-Typ und den Token-Algorithmus), die Nutzlast (enthält Benutzer- und Metadaten-„Ansprüche“) und die Signatur (überprüft den Absender und schützt vor Manipulationen). JWTs werden häufig zur Benutzerauthentifizierung und -autorisierung in Webanwendungen verwendet und ermöglichen einen sicheren Zugriff auf Dienste und Ressourcen.</p>
      <p>In der Beispielanwendung, die wir in diesem Blog untersuchen werden, simulieren wir den Backend-Prozess, der stattfindet, nachdem ein Benutzer sich erfolgreich angemeldet hat. Im Speziellen konzentrieren wir uns darauf, wie ein JWT mit einem symmetrischen Schlüssel generiert und dem Benutzer zurückgegeben wird. Dieses Token dient als Nachweis für die Authentifizierung und ermöglicht es dem Benutzer, auf geschützte Ressourcen zuzugreifen, ohne dass eine traditionelle Sitzung auf dem Server aufrechterhalten werden muss. Für die Zwecke dieser Beispielanwendung verwenden wir einen symmetrischen Schlüsselansatz, obwohl in realen Szenarien komplexere Schlüsselstrukturen eingesetzt werden könnten.</p>
     
      <h3>Lass uns gehen!</h3>
      <p>Dieses vollständige Projekt wird auf meinem GitHub über den folgenden Link verfügbar sein: Wenn Sie jedoch meinen Anweisungen folgen möchten, seien Sie mein Gast.</p><p></p>
      <ol>
        <li>Erstellen Sie in Visual Studio ein leeres Web-API-Projekt.
            </li>
            <li>Fügen Sie als Nächstes ein neues Testprojekt zur Stammlösung des Projekts hinzu. In diesem Blog verwende ich das xUnit-Framework.
                </li>
                <li>Vergessen Sie nach dem Hinzufügen des Testprojekts nicht, auf das ursprüngliche Web-API-Projekt zu verweisen, das im ersten Schritt erstellt wurde.
                    </li>
                    </ol>
                    <p></p>
                    <h3>Unit-Test für die Token-Erzeugungsmethode</h3>

                    <p>Bevor wir mit dem Schreiben des Unit-Tests für die Erzeugung des JWT beginnen, ist es wichtig zu skizzieren und zu planen, was die konkrete Implementierung beabsichtigt zu tun, damit wir unseren Test einfacher schreiben können. 
Die Erzeugung des Tokens hängt ausschließlich davon ab, dass er über die Token-Handler-Wrapper-Klasse erstellt wird, die über eine Methode in der Repository-Klasse aufgerufen wird. Eine Wrapper-Klasse ist ein benutzerdefiniertes Objekt, das darauf ausgelegt ist, ein anderes Objekt zu kapseln oder "einzupacken", oft aus einer Drittanbieter-Bibliothek, einem externen System oder eingebauten Systemklassen. Ihr Zweck ist es, eine vereinfachte oder passendere Schnittstelle zu bieten, Funktionalität zu erweitern oder zu begrenzen, oder eine Möglichkeit zu bieten, das Verhalten des eingepackten Objekts anzupassen oder zu modifizieren. Klassen-Wrapper sind perfekt für TDD, da Drittanbieterdienste leicht über ihre umhüllte Klasse gemockt werden können. Wir werden das Mocking in Kürze näher erörtern. Jetzt beginnen wir mit dem Schreiben des Unit-Tests.</p>

<p>Im Einklang mit dem TDD-Ansatz wird unser anfänglicher Test neutral sein, da er leer ist. Aber während wir unseren Test verbessern und verfeinern, werden wir die Handler-Wrapper-Klasse parallel dazu entwickeln. Dieser iterative Prozess wird dazu führen, dass unsere Tests zwischen bestandenem und nicht bestandenem Zustand wechseln:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class JWTRepositoryTests
{
    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {

    }
}
`}</code></pre></div>
<p>Jetzt, da wir den leeren Test eingerichtet haben, werden wir mit dem Moq-Framework ein Mock unseres JWT-Handler-Wrapper-Klasse einführen. Aber was genau ist Mocking?</p>
<p></p>
<p>Mocking in TDD bietet eine Möglichkeit, Codeeinheiten zu isolieren, um sicherzustellen, dass Tests sich ausschließlich auf die Ziel-Funktionalität konzentrieren, ohne von externen Abhängigkeiten beeinflusst zu werden. Durch die Verwendung von Mocks laufen Tests schneller und sind frei von den Latenzzeiten realer Vorgänge wie Datenbank- oder Netzwerkinteraktionen. Mocks bieten auch Kontrolle, indem sie Entwicklern ermöglichen, spezifische Verhaltensweisen zu simulieren, einschließlich Randfällen und Fehlerbedingungen, ohne reale Nebenwirkungen. Diese kontrollierte Umgebung stellt sicher, dass Tests das Verhalten des Codes unter einer Vielzahl von Szenarien genau bewerten und somit Robustheit und Zuverlässigkeit gewährleisten.</p>
<p></p>
<p>In diesem Zusammenhang mocken wir die JWT Handler-Wrapper-Klasse, um sicherzustellen, dass wir beim Testen einer Methode, die sich auf den JWT Handler-Wrapper stützt, nur die Logik dieser Methode bewerten und nicht die inneren Abläufe der konkreten JWT Handler-Klasse selbst. Diese Unterscheidung garantiert, dass jedes Testergebnis ausschließlich der Methode zugeschrieben wird, die wir bewerten, und nicht einem unerwarteten Verhalten des JWT Handlers:</p>

<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class JWTRepositoryTests
{
    private readonly Mock&lt;IJwtHandlerWrapper&gt; _jwtHandlerMock;
    
    public JWTRepositoryTests()
    {
        _jwtHandlerMock = new Mock<IJwtHandlerWrapper>();
    }

    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {
        //Arrange
        _jwtTokenHandlerMock.Setup(th =&gt; th.WriteToken(It.IsAny&lt;SecurityToken())).Returns("fakeTokenString");
    }
}
`}</code></pre></div>
<p>Zu diesem Zeitpunkt haben wir nur das Scheinverhalten für die WriteToken-Methode eingerichtet, aber keine Assertion in unseren Test aufgenommen. Das ist Absicht. Im weiteren Verlauf basiert unser Test auf einer Methode aus unserer Repository-Klasse, die mit dem JWT-Handler-Wrapper interagiert. Sobald wir diese Methode in unsere Tests einführen, fügen wir die notwendigen Behauptungen hinzu, um ihr Verhalten in Verbindung mit dem JWT-Handler zu validieren.
   </p> 
   <p>Der nächste Schritt auf unserer Reise wird darin bestehen, die eigentliche IJwtTokenHandler-Schnittstelle, -Klasse und -Methode zu implementieren, die sich daran hält:</p>    
   <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class JWTHandlerWrapper : IJwtHandlerWrapper
{
    private readonly JwtSecurityTokenHandler _tokenHandler;
    
    public JWTHandlerWrapper()
    {
        _tokenHandler = new JwtSecurityTokenHandler();
    }
    
    public string WriteToken(SecurityToken token)
    {
        return _tokenHandler.WriteToken(token);
    }
}
`}</code></pre></div>
   <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public interface IJwtHandlerWrapper
{
    string WriteToken(SecurityToken token);
}
`}</code></pre></div>
<p>Nachdem wir den IJwtTokenHandler implementiert haben, richtet sich unsere Aufmerksamkeit nun auf die Repository-Klasse. Diese Klasse wird eine Methode enthalten, die für die Token-Generierung auf den JWT-Handler-Wrapper angewiesen ist, was die Einbindung der Wrapper-Klasse als Abhängigkeit erfordert. Um auf die Konfigurationseigenschaften der Anwendung zugreifen zu können, wird eine weitere Abhängigkeit benötigt, insbesondere um den geheimen symmetrischen Schlüssel aus der appsettings.json-Datei zu lesen. Obwohl das direkte Speichern eines symmetrischen Schlüssels in der Konfiguration nicht die beste Praxis für Produktions-Apps ist, kann das zugrunde liegende Konzept angepasst werden, um eine URL (wie z.B. Azure Key Vault) zu referenzieren, von der aus der symmetrische Schlüssel sicher abgerufen werden kann. Mit der Anordnung des Repositories an Ort und Stelle ist unser nächster Schritt im TDD-Prozess die Aktualisierung des Unit-Tests. Dies wird die Arrange-Sequenz beinhalten, in der wir unsere Voraussetzungen aufstellen, gefolgt von der Act-Sequenz, in der wir die CreateToken-Methode im Repository aufrufen. Schließlich werden wir sicherstellen, dass unser Test die notwendigen Assertions für die JWT-Generierungsmethode abdeckt:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class JWTRepositoryTests
{

    private readonly Mock<IJwtHandlerWrapper> _jwtHandlerMock;
    private readonly Mock<IConfiguration> _configurationMock;
    private readonly User _user;

    public JWTRepositoryTests()
    {
        _jwtHandlerMock = new Mock<IJwtHandlerWrapper>();
        _configurationMock = new Mock<IConfiguration>();
        _user = new User { Username = "TestUsername", Password = "TestPassword", Role = "testRole" };

        // Setup mock configuration values
        _configurationMock.SetupGet(x => x["Jwt:Secret"]).Returns("your-secret-value");
        _configurationMock.SetupGet(x => x["Jwt:Issuer"]).Returns("your-issuer-value");
        _configurationMock.SetupGet(x => x["Jwt:Audience"]).Returns("your-issuer-value");
    }

    [Fact]
    public void Generate_JWT_Token_Succesfully_Writes_Token()
    {
        //Arrange
        _jwtTokenHandlerMock.Setup(th => th.WriteToken(It.IsAny<SecurityToken>())).Returns("fakeTokenString");
        var repository = new JWTRepository(_configurationMock.Object, _jwtTokenHandlerMock.Object);

        //Act
        var token = repository.GenerateToken(_user);

        //Assert
        Assert.Equal("fakeTokenString", token);
    }
}
`}</code></pre></div>

<p>Im Konstruktor dieser Testklasse haben wir ein Test-Mock-User-Objekt erstellt. Diese Designentscheidung erhöht die Wartbarkeit des Tests; indem wir den Benutzer einmal im Konstruktor initialisieren, ermöglichen wir, dass dieselbe Benutzerinstanz über mehrere Tests hinweg geteilt wird und verhindern Redundanz. Im weiteren Verlauf des Konstruktors der Testklasse haben wir auch Mock-Konfigurationswerte eingerichtet, die alle Einstellungen nachahmen, die in der appsettings.json-Datei vorhanden sind. Bei Verwendung einer gemockten Instanz von IConfiguration werden solche Einstellungen in appsettings.json nicht gelesen, daher ist es wichtig, dass wir uns daran erinnern, diese gemockten Werte zu erstellen.
Wenn wir zum Unit-Test selbst übergehen, beinhaltet die Act-Phase den Aufruf der `GenerateToken`-Methode unter Verwendung des gemockten Benutzers als Eingabe. Dies betont, wie ein Token für einen Benutzer bei erfolgreicher Authentifizierung generiert werden könnte. Um die Richtigkeit des Verhaltens unserer Methode zu überprüfen, bestätigt die Assert-Phase, dass das zurückgegebene Token unseren Erwartungen entspricht.
Mit diesem gut strukturierten Unit-Test, der das Mocking zu seinem Vorteil nutzt, können wir nun mit der konkreten Implementierung der Repository-Klasse sowie der Benutzerdatenstrukturklasse fortfahren.</p>
<h3>Implementierung der Repository-Klasse, Methode & Benutzermodell</h3>


<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class JWTRepository
{
    private readonly IConfiguration _configuration;
    private readonly IJwtHandlerWrapper _jwtHandler;
    
    public JWTRepository(IConfiguration configuration, IJwtHandlerWrapper jwtHandler)
    {
        _configuration = configuration;
        _jwtHandler = jwtHandler;
    }
    
    public string GenerateToken(User user)
    {
        try
        {
            var securityKey = new SymmetricSecurityKey(Encoding.UTF8.GetBytes(_configuration["Jwt:Secret"]));
            var credentials = new SigningCredentials(securityKey, SecurityAlgorithms.HmacSha256);
            var claims = new[]
            {
                new Claim(ClaimTypes.NameIdentifier,user.Username),
                new Claim(ClaimTypes.Role,user.Role)
            };
            
            var token = new JwtSecurityToken(_configuration["Jwt:Issuer"],
              _configuration["Jwt:Audience"],
              claims,
              expires: DateTime.UtcNow.AddSeconds(30),
              signingCredentials: credentials);
              
            var tokenString = _jwtHandler.WriteToken(token);
            
            return tokenString;
        }
        
        catch(Exception ex)
        {
            var error = ex.ToString();
            return null;
        }
    }
}
`}</code></pre></div>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class User
{
    public string Username { get; set; }
    public string Password { get; set; }
    public string Role { get; set; }
}
`}</code></pre></div>
<p>Das war's, wir haben jetzt einen erfolgreichen Unit-Test und auch die konkrete Implementierung ist abgeschlossen! Die endgültige Funktion der Anwendung besteht darin, einen Controller-Endpunkt zu erstellen, mit dem Ziel, einen Benutzer zu authentifizieren. Diese Controller-Methode wird die GenerateToken-Repository-Methode aufrufen und dem Benutzer bei erfolgreicher Authentifizierung ein Token zuweisen. Natürlich werden wir, da es sich um einen TDD-Ansatz handelt, zuerst einen fehlerhaften Unit-Test für die Controller-Methode schreiben und diesen Unit-Test schrittweise erweitern, während wir unsere konkrete Implementierung der Controller-Methode hinzufügen. Lassen Sie uns fortfahren! </p>

<h3>Unit-Test für den Controller-Authentifizierungsendpunkt</h3>
<p>Zunächst werden wir unter unserem Testprojekt eine neue Klassen-Datei erstellen und sie ControllerTests nennen. Dies hilft uns, unsere Tests organisiert zu halten, indem wir Repository-Tests von Controller-Tests trennen und auch potenzielle zukünftige Integrationstests. Bevor wir den Unit-Test erstellen, werden wir das gewünschte Verhalten der konkreten Implementierung des Controller-Tests besprechen.</p>
<p>Zu Demonstrationszwecken werden der Benutzername und das Passwort des Benutzers innerhalb des HTTP-Headers an die Methode des Controllers übergeben. Ein vordefinierter Benutzer wird in der appsettings.json-Datei eingerichtet. Daher werden wir, wie bereits zuvor, diese Details zusammen mit IConfguration verspotten müssen. In diesem Unit-Test muss auch die Repository-Klasse verspottet werden, um dem Benutzer während der Authentifizierung das JWT zuzuweisen.</p>
<p>Um zu bewerten, ob der Unit-Test bestanden hat oder fehlgeschlagen ist, werden wir überprüfen, ob die Controller-Methode einen Statuscode von 200 zurückgibt, was bedeutet, dass der Benutzer erfolgreich authentifiziert wurde. Lassen Sie uns mit der Testimplementierung beginnen:</p>

<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class ControllerTests
{
    private Mock<IConfiguration> _mockConfiguration;
    private Mock<IJWTRepository> _mockJwtRepository;
    private readonly User _mockUser;
    
    public ControllerTests()
    {
        _mockConfiguration = new Mock<IConfiguration>();
        _mockJwtRepository = new Mock<IJWTRepository>();
        _mockUser = new User { Username = "TestUsername", Password = "TestPassword", Role = "testRole" };

        _mockConfiguration.SetupGet(x => x["TestUsers:Username"]).Returns("TestUsername");
        _mockConfiguration.SetupGet(x => x["TestUsers:Password"]).Returns("TestPassword");
    }
    
    [Fact] 
    public async Task AuthenticateUser_Returns_OK()
    {
        //Arrange
        var jwtReppsitoryMock = new Mock<IJWTRepository>();
        jwtReppsitoryMock.Setup(r => r.GenerateToken(_mockUser)).Returns("testToken");

        var controller = new JwtController(_mockConfiguration.Object, _mockJwtRepository.Object);
        controller.ControllerContext.HttpContext = new DefaultHttpContext();
        controller.HttpContext.Request.Headers["username"] = "TestUsername";
        controller.HttpContext.Request.Headers["password"] = "TestPassword";

        //Act
        var result = controller.AuthenticateUser();

        //Assert

        var objectResult = Assert.IsType<OkObjectResult>(result);
        Assert.Equal(200, objectResult.StatusCode);
    }
}
`}</code></pre></div>
<p>Mit der oben genannten Implementierung sollten wir, sobald wir die Controller-Klassen-Datei erstellen, einen erfolgreichen Unit-Test für die erfolgreiche Authentifizierung des Benutzers haben, während gleichzeitig ein JWT generiert wird. Lassen Sie uns zur konkreten Controller-Klasse übergehen. </p>
<h3>Controller-Klasse & Implementierung der Authentifizierungs-Endpunkt-Methode</h3>

<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`[Route("api/[controller]")]
[ApiController]
public class JwtController : ControllerBase
{
    private readonly IJWTRepository _jwtRepository;
    private readonly IConfiguration _configuration;
    
    public JwtController(IConfiguration configuration, IJWTRepository jWTRepository)
    {
        _configuration = configuration;
        _jwtRepository = jWTRepository;
    }
    
    [HttpPost("/AuthenticateUser")]
    public IActionResult AuthenticateUser()
    {
        string httpHeaderUsername = Request.Headers["username"].FirstOrDefault();
        string httpHeaderPassword = Request.Headers["password"].FirstOrDefault();

        User user = new User
        {
            Username = httpHeaderUsername,
            Password = httpHeaderPassword,
            Role = "User"
        };
        try
        {
            string username = _configuration["TestUsers:Username"];
            string password = _configuration["TestUsers:Password"];

            if (httpHeaderUsername == username &amp;&amp; httpHeaderPassword == password)
            {
                var token = _jwtRepository.GenerateToken(user);
                return Ok(token);
            }
            else
            {
                return StatusCode(401, "Incorrect credentials");
            }
        }
        catch (Exception ex)
        {
            return StatusCode(500, ex.Message);
        }
    }
}
`}</code></pre></div>
<p>Das war's! Jetzt, da die konkrete Implementierung abgeschlossen ist, können wir die Benutzerauthentifizierung zusammen mit der JWT-Generierung manuell mit einem API-Tool wie Postman testen. Stellen Sie die URL auf POST und fügen Sie die localhost ULR zusammen mit dem Port hinzu, auf dem Ihre Anwendung läuft. Sie können den Benutzernamen und das Passwort im HTTP-Headers-Bereich übergeben. Bei erfolgreicher Authentifizierung erhalten Sie eine 200 OK Code-Antwort zusammen mit einem Token. Siehe das folgende Bild: </p>
<img src="https://kmc-technologies.ltd/JWT-TDD-Blog-Postman.png"></img>
<p>Zurzeit bringt uns das zum Ende dieses Blogs, aber Teil 2 wird bald kommen, wo wir auch auf fehlerhafte Vorgänge beim Generieren des JWT testen und Integrationstests hinzufügen werden. Bleiben Sie dran!  </p>
<br></br>

   </div>)
        //***************************************** End DE Content ********************************************************** */

  },
  {
    link: "/blog/azure-key-vault-net-core",
    img: "key-vault-blog-image-title.svg",
    title: "Setting Up & Using Azure Key Vault",
    titleDe: "Einrichten und Verwenden von Azure Key Vault",
    desc: "Setting up and using Azure Key Vault in a .Net Core web API project for retriving confidential values securely",
    desdDe: "Einrichten und Verwenden von Azure Key Vault in einem .Net Core-Web-API-Projekt zum sicheren Abrufen vertraulicher Werte",
    date: "30th August 2023",
    dateDe: "30. August 2023",
    initialContent: (<div></div>),
    content: (
    <div class="content-item">
    <p>Welcome to our deep dive into the world of Microsoft Azure's secure information management – the Azure Key Vault. In this digital era, where data breaches and security concerns are part and parcel of every online venture, having an iron-clad method to manage sensitive information is crucial. As developers, it's our responsibility to ensure that our applications are not just robust and efficient, but also safe and trustworthy. This blog aims to guide you on a journey through integrating Azure Key Vault within your .NET Core web API project, ensuring that your secrets remain just that – secret.</p>
    <h2>But first, what is Azure Key Vault?</h2>
    <p>Azure Key Vault is a cloud service provided by Microsoft Azure designed specifically for safeguarding cryptographic keys and secrets (like connection strings, API keys, or any sensitive data). Think of it as a bank vault where you can store your most valuable digital assets. Instead of embedding these secrets directly in your code, which can lead to potential exposure, Azure Key Vault provides a centralised and secure storage system. This way, only applications and users with the appropriate permissions can access them.</p>
    <p>Stay with us as we navigate through the setup, intricacies, and best practices of incorporating Azure Key Vault into your .NET Core Web API projects. Whether you're a seasoned Azure developer or just getting your feet wet in the vast ocean of cloud computing, this blog aims to provide insights and steps to bolster the security of your digital solutions.</p>
    <h2>Getting started & prerequisites</h2>
    <h3>Prerequisites</h3>
    <ul>
    <li>Basic understanding of C#.</li>
    <li>Basic experience using Microsoft Visual Studio.</li>
    <li>Basic experience using Microsoft Azure.</li></ul>
    <h3>Getting started</h3>
    <p>We will get started by creating a .NET Core project in Microsoft Visual Studio. For demonstration purposes, we will use the good old weather forecast web API template provided by Microsoft.</p>
    <p></p>
    <ol>
    <li>In the project templates, search for "API" and select the "ASP.NET Core Web API" option.</li>
    <li>Name the project; I've named mine "AzureKeyVaultExample".</li>
    <li>On the additional information window, leave everything that's pre-selected and click "Create".</li>
</ol>
<p>With our demo project now in place, our next move is to deploy it to Microsoft Azure. This deployment isn't merely a formality. By publishing the application, we can incorporate it into a resource group on Azure. Furthermore, if you're aiming to use Azure Key Vault, it's a prerequisite to have your application registered within Azure. Follow these steps to deploy your application to Azure:</p>

<h3>Deploying to Azure</h3>
<p>Right-click on the main project file and click "Publish".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-1.png"></img>
<p>Next, click on "Add a publish profile", select "Azure" and click "Next".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-2.png"></img>
<p>Now click "Azure App Service (Windows)", followed by clicking "Next".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-3.png"></img>
<p>Click "Create new".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-4.png"></img>
<p>Within the new app service window, we are going to create a new resource group.</p>
<p>Firstly, select whichever Azure subscription you wish to use.</p>
<p>Next, in the "Resource group" section, click on "New".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-5.png"></img>
<p>Name the resource group as you wish. I've named mine "RG-Azure-Key-Vault-Demo". For my objects in Azure, I like to abbreviate the object type before the name for easy recognition. In this case, "RG" is "Resource Group".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-6.png"></img>
<p>Now, under the "Hosting Plan" section, click "New".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-7.png"></img>
<p>Within the hosting plan window, make your selections and click "Next" when you're done.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-8.png"></img>
<p>With the new app service created, we can now click on "Create".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-9.png"></img>
<p>In the publish window, select the new app service we just created and click "Next".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-10.png"></img>
<p>After clicking next, you will be taken to the API management screen (the tab highlighted in yellow). We are going to create a new one for this project, so click "Create new".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-11.png"></img>
<p>In the new API management screen, you'll be asked to provide a name. Provide a name where highlighted in yellow below. After this step, under the "Resource group" section, select the new resource group we just created. Upon finishing this step, in the "API Management service" section, click on "New".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-12.png"></img>
<p>In the new API management service screen, give the service an appropriate name and set the options in the other sections best suited to your needs. When finished, click "Ok".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-13.png"></img>
<p>We are now directed back to the previous window. For now, we can ignore the "API URL suffix" section and click on "Create". This will take a few moments, so feel free to go make a tea or coffee whilst you wait! ☕️.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-14.png"></img>
<p>Next, under the new resource group and API management service we have just created, click on the new API and click "Finish".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-15.png"></img>
<p>Lastly, we are now ready to publish to Azure. Click on "Publish" to continue.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-16.png"></img>
<p>Upon successful publication, a browser window will pop up with the weather forecast template via a live URL. If you add "/WeatherForecast" to the end of the URL, you will be able to see the JSON response via the pre-defined "Get" controller endpoint.</p>
<p>Please note that this URL is live, and you may want to secure it using IP restrictions so that you don't receive unwanted traffic and hit data usage quotas.</p>
<p>With the Visual Studio project now live in Azure, we can now register the application so that when configuring Azure Key Vault, the service is aware of our newly deployed application.</p>

<h2>Registering the Application</h2>
<p>Assuming you are already logged into the Azure portal, navigate to "App registrations". If you are unsure how to find it, just search for "App registrations" in the top search bar.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-1.png" />
<p>Within the "App registrations" menu, click on "New registration."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-2.png" />
<p>Give the app registration a name and leave the rest of the details as they are. Click "Register". You can leave the rest of the options at their default values.</p>

<p>That's it for this section at this moment. We will come back to the application registration later in this blog to create a client secret value, while also adding the relevant configuration parameters to our Visual Studio project.</p>

<h2>Key Vault Configuration</h2>
<p>In this section, we will create an Azure Key Vault and set a secret value that can be retrieved from the key vault.</p>
<p>Navigate to "Key Vaults" within the Azure portal or type "Key Vaults" in the main search bar.</p>

<h3>Creating the Azure Key Vault</h3>
<p>In the "Azure Key Vaults" menu, click on "Create."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-1.png" />
<p>In the next screen, select the subscription you'd like to use. Select the newly created resource group that we created when publishing the application from Visual Studio. Give the new key vault a name and select your desired region. Once you are happy with the rest of the options, click "Review and create."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-2.png" />
<p>In the "Review & Create" screen, click on "Create."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-3.png" />

<h3>Account RBAC Role</h3>
<p>Before creating the secret key and value, we need to set a new RBAC role that assigns your Azure account as an administrator within the newly created key vault. To do this, from the main menu in the left sidebar, click on "Access control (IAM)."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-1.png" />
<p>In the following screen, click on "Add role assignment."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-2.png" />
<p>Within the "Role Assignment" screen, in the search bar, search for "Key Vault Administrator," select the "Key Vault Administrator" option, and click on "Next."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-3.png" />
<p>In the following screen, click on "Select members" and search for the name or email associated with your Azure account. After selecting your account, click on "Next."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-4.png" />
<p>Next, click on "Review + Assign." After this, you'll be redirected back to the main menu of the key vault.</p>

<h3>Creating the Secret Key and Value</h3>
<p>It's now time to create the secret key and value. Within the key vault's main menu, click on the "Secrets" option in the left sidebar.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-4.png" />
<p>In the following window, click on "Generate / Import."</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-5.png" />
<p>In the "Create a Secret" screen, give the secret a key value. This key value will be used when retrieving the secret value from the key vault. Set the secret value and click on "Create." All other options can remain at their default settings.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-6.png" />
<p>After clicking on "Create," you will be directed to the secrets menu of the key vault. A message should display stating that the secret has successfully been created.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-7.png" />

<h2>Retrieving the Secret Value from Azure Key Vault</h2>
<p>Now that we have configured the application's key vault, we can start accessing the secret value via the Visual Studio project we previously created and deployed to Azure.</p>
<p>To use Azure Key Vaults in our application, we need to install some packages. Return to the Visual Studio project that we deployed to Microsoft Azure earlier and open the NuGet Package Manager.</p>
<p>With the "Browse" tab selected, search for "Azure.Identity". When you find the option, select it. In the right-side window, choose the "Latest stable" version and click "Install".</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-1.png"></img>
        <p>After installing the above package, type "Azure.Security.KeyVault.Secrets" into the search bar. Install the latest stable package version.</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-2.png"></img>
        <p>We now need to install our final package. In the search bar, type "Azure.Extensions.AspNetCore.Configuration.Secrets", then select and install the latest stable version of the package.</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-3.png"></img>

        <p>Next, we'll start storing our key vault configuration parameters within our Visual Studio app.</p>
<p>In your project's main directory, navigate to the appsettings.json file and add the following:</p>
        <div class="code-block"><pre><code class="language-csharp">{`"AzurekeyVaultConfig": {
  "KVUrl": "",
  "KVClientId": "",
  "KVTenantId": "",
  "KVClientSecretId": ""
 },
`}</code></pre></div>
       <p>To populate the empty values we just added, the next steps will involve going back to our application registration in Azure that we completed earlier to create a new client secret.</p>
<p>Navigate to "App registrations" within the Azure portal. Find and click on the application you previously registered to go to its overview screen.</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-4.png"></img>
        <p>In the app registration's main menu, click on "Certificates & secrets" from the left sidebar. Next, click on "New client secret".</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-3.png"></img>
        <p>In the new window that appears, name the new client secret. Set the expiration to your desired value; I've left it at the default of 180 days. Click "Add".</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-4.png"></img>
        <p>After the new client secret is created, make a note of the secret value in the "Value" column of the table:</p>
        <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-5.png"></img>
        <p>Return to the appsettings.json file and set this value as the "KVClientSecretId" key:</p>
        <div class="code-block"><pre><code class="language-csharp">{`"AzurekeyVaultConfig": {
  "KVUrl": "",
  "KVClientId": "",
  "KVTenantId": "",
  "KVClientSecretId": "Client-Secret-Id-Goes-Here"
 },
`}</code></pre></div>

      <p>Next, navigate to the overview tab of the app registration. We will be need to make a note of the paramaters "Application (client) ID" & "Directory (tenant) ID":</p>
      <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-6.png"></img>
      <p>In the appsettings.json file within the Visual Studio project, set the "Application (client) ID" as the value for "KVClientId" and the "Directory (tenant) ID" as the value for "KVTenantId":</p>
      <p></p>

      <div class="code-block"><pre><code class="language-csharp">{`"AzurekeyVaultConfig": {
  "KVUrl": "",
  "KVClientId": "Client-ID-Goes-Here",
  "KVTenantId": "Tenant-ID-Goes-Here",
  "KVClientSecretId": "Client-Secret-Id-Goes-Here"
 },
`}</code></pre></div>
   <p>Lastly, to assign the value for the key "KVUrl," we need to obtain the URL of the new Azure Key Vault we created earlier.</p>
<p>To do this, navigate to the Key Vaults console within the Azure portal. In the Key Vaults window, search for and find the key vault you created earlier, then click on it. After completing this step, you'll be directed to the overview screen of the key vault:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-7.png"></img>
<p>Copy the value of the parameter "Vault URI" and paste it as the value for the "KVUrl" key within the Visual Studio appsettings.json file:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-8.png"></img>
<div class="code-block"><pre><code class="language-csharp">{`"AzurekeyVaultConfig": {
  "KVUrl": "Web-API-URL-Goes-Here",
  "KVClientId": "Client-ID-Goes-Here",
  "KVTenantId": "Tenant-ID-Goes-Here",
  "KVClientSecretId": "Client-Secret-Id-Goes-Here"
},
`}</code></pre></div>

<h3>Modifying program.cs</h3>
<p>Now that we have our configuration parameters in place, let's modify the program.cs file within the main directory of the Visual Studio project. This will allow us to load the newly added key vault configurations within the application's main entry point.</p>
<p>Add the following lines to program.cs:</p>
<div class="code-block"><pre><code class="language-csharp">{`string keyVaultUrl = builder.Configuration["AzurekeyVaultConfig:KVUrl"];
string tenantId = builder.Configuration["AzurekeyVaultConfig:KVTenantId"];
string clientId = builder.Configuration["AzurekeyVaultConfig:KVClientId"];
string clientSecretId = builder.Configuration["AzurekeyVaultConfig:KVClientSecretId"];

builder.Configuration.SetBasePath(builder.Environment.ContentRootPath)
    .AddJsonFile("appsettings.json", optional: false, reloadOnChange: true)
    .AddEnvironmentVariables();

var clientSecretCredential = new ClientSecretCredential(tenantId, clientId, clientSecretId);
var secretClient = new SecretClient(new Uri(keyVaultUrl), clientSecretCredential);
`}</code></pre></div>

<p>Next, we will add a dependency injection line to our program.cs file. This enables our controller to access our repository methods without having to create a new instance of our repository class each time a user hits our controller endpoint. To achieve this, add the following lines of code underneath the commented line "//Add services to the container":</p>

<div class="code-block"><pre><code class="language-csharp">{`builder.Services.AddScoped&lt;IRepository, Repository&gt;();
`}</code></pre></div>

<p>Please note that adding the lines above will temporarily generate an error because the class and interface have not been created yet. We will add them shortly.</p>

<p>Finally, if Visual Studio does not automatically import the necessary namespaces after you add the above lines of code, add the following import statements to the top of the file:</p>
<div class="code-block"><pre><code class="language-csharp">{`using Azure.Identity;
using Azure.Security.KeyVault.Secrets;
`}</code></pre></div>
<p>Your overall program.cs file should now look something like this:</p>

<div class="code-block"><pre><code class="language-csharp">{`using Azure.Identity;
using Azure.Security.KeyVault.Secrets;

var builder = WebApplication.CreateBuilder(args);

string keyVaultUrl = builder.Configuration["AzurekeyVaultConfig:KVUrl"];
string tenantId = builder.Configuration["AzurekeyVaultConfig:KVTenantId"];
string clientId = builder.Configuration["AzurekeyVaultConfig:KVClientId"];
string clientSecretId = builder.Configuration["AzurekeyVaultConfig:KVClientSecretId"];
builder.Configuration.SetBasePath(builder.Environment.ContentRootPath)
    .AddJsonFile("appsettings.json", optional: false, reloadOnChange: true)
    .AddEnvironmentVariables();

var clientSecretCredential = new ClientSecretCredential(tenantId, clientId, clientSecretId);

var secretClient = new SecretClient(new Uri(keyVaultUrl), clientSecretCredential);

// Add services to the container.
builder.Services.AddScoped&lt;IRepository, Repository&gt;();

builder.Services.AddControllers();
// Learn more about configuring Swagger/OpenAPI at https://aka.ms/aspnetcore/swashbuckle
builder.Services.AddEndpointsApiExplorer();
builder.Services.AddSwaggerGen();

var app = builder.Build();

// Configure the HTTP request pipeline.
if (app.Environment.IsDevelopment())
{
    app.UseSwagger();
    app.UseSwaggerUI();
}

app.UseHttpsRedirection();

app.UseAuthorization();

app.MapControllers();

app.Run();
`}</code></pre></div>
<h3>Implementing the Repository and Interface Methods</h3>
<p>In the last step of modifying program.cs, we added a dependency injection line. We will now proceed to create the repository and interface that correspond to this line of code.</p>

<p>Create a folder in the main directory of your Visual Studio project and name it "Repositories." Create another folder and name it "Interfaces."</p>
<p>In the "Repositories" folder, create a new class file named "Repository.cs."</p>
<p></p>
<p>Return to the "Repository.cs" class file and ensure it inherits from the "IRepository" interface that we just created, like so:</p>
<div class="code-block"><pre><code class="language-csharp">{`public class Repository : IRepository
{

}
`}</code></pre></div>

<p>After adding the repository and interface, we can now reference them within our program.cs file. At the top of the file, add the following import statements, making sure to use your project's name:</p>
<div class="code-block"><pre><code class="language-csharp">{`using AzureKeyVaultExample.Interface;
using AzureKeyVaultExample.Repository;
`}</code></pre></div>

<p>Navigate back to the newly created "Repository.cs" file and declare a field of type IConfiguration. Initialize the constructor as follows:</p>
<div class="code-block"><pre><code class="language-csharp">{`public class Repository : IRepository
{
    private readonly IConfiguration _configuration;
    public Repository(IConfiguration configuration)
    {
        _configuration = configuration;
    }
}
`}</code></pre></div>

<p>The purpose of this field is to allow us to read various values from the appsettings.json file that we will need for the key vault configuration.</p>

<p>Next, in our Repository class file, we will add a method that retrieves the secret value we created within Azure Key Vaults. This method will later be used in our controller class file.</p>

<p>Add the following lines of code to "Repository.cs":</p>

<div class="code-block"><pre><code class="language-csharp">{`public string GetSecretValue(string secretName)
{
    string keyVaultUrl = _configuration["AzurekeyVaultConfig:KVUrl"];

    var client = new SecretClient(new Uri(keyVaultUrl), new DefaultAzureCredential());
            
    try
    {
        KeyVaultSecret secret = client.GetSecret(secretName);
        return secret.Value;
    }
    catch (Exception ex)
    {
        // Handle the exception as appropriate for your application.
        Console.WriteLine($"Error retrieving secret: {ex.Message}");
        return null;
    }
}
`}</code></pre></div>
<p>In the above code snippet, the URL for the key vault is read from our appsettings.json file. The secret name is passed from our controller endpoint that will call this method, which we will implement shortly.</p>

<p>Next, navigate back to the interface file "IRepository.cs" and ensure you add the new method we implemented in "Repository.cs". This ensures that it adheres to the repository contract:</p>
<div class="code-block"><pre><code class="language-csharp">{`public interface IRepository
{
    public string GetSecretValue(string secretName);
}
`}</code></pre></div>

<p>Since this is a demo on how to use Azure Key Vaults, we will not create a wrapper class for Microsoft's Azure Key Vaults class objects. However, in a production application, it would be advantageous to use a wrapper class for controlled interaction, testing purposes, and adaptability. Stay tuned for another blog where we will discuss implementing Azure Key Vaults in a TDD approach.</p>

<h3>Modifying the Controller</h3>

<p>In this section, we will modify the file "WeatherForecastController.cs" so that the weather forecast endpoint will retrieve the secret value stored in Azure Key Vaults, provided the correct key value is supplied.</p>

<p>Open "WeatherForecastController.cs" and add the following code. This code will allow the constructor of the class to use the repository dependency injection we created earlier, along with field declarations for the repository and configuration interfaces:</p>
<div class="code-block"><pre><code class="language-csharp">{`private readonly ILogger&lt;WeatherForecastController&gt; _logger;
private readonly IRepository _repository;
private readonly IConfiguration _configuration;

public WeatherForecastController(ILogger&lt;WeatherForecastController&gt; logger, IRepository repository, IConfiguration configuration)
{
    _logger = logger;
    _repository = repository;
    _configuration = configuration;
}
`}</code></pre></div>

<p>Next, navigate to the "Get" method in the controller and replace the existing method with the following code:</p>
<div class="code-block"><pre><code class="language-csharp">{`[HttpGet(Name = "GetWeatherForecast")]
public IEnumerable&lt;WeatherForecast&gt; Get(string secretName)
{
    if (_repository.GetSecretValue(secretName) != null)
    {
        return Enumerable.Range(1, 5).Select(index =&gt; new WeatherForecast
        {
            Date = DateTime.Now.AddDays(index),
            TemperatureC = Random.Shared.Next(-20, 55),
            Summary = Summaries[Random.Shared.Next(Summaries.Length)]
        })
        .ToArray();
    }
    else
    {
        return null;
    }
}
`}</code></pre></div>

<h3>Running the Application</h3>
<p>With the "Get" controller updated, it's time to test whether retrieving the secret is working as expected. Make sure to note down the key of the secret you created in the Azure Key Vault earlier, so that you can pass it as an argument to the controller endpoint:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-9.png"></img>

<p>Run the application. Once the Swagger main page is loaded, click on the "Try it out" button:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-10.png"></img>

<p>After that, make sure to type in the correct name of the key for the secret we created in the key vault and click on "Execute". Within a few seconds, the weather forecast JSON results will be returned to you. If you provide an invalid key name, you should receive an error 204 message:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-11.png"></img>

<p>That concludes this blog post. In a future blog, I will implement this setup in a more TDD-focused manner, along with some coding best practices and principles. Until then, goodbye! :)</p>


<br></br>
      </div>
    ),
    contentDe:(<div class="content-item">
       <p>Willkommen zu unserem ausführlichen Einblick in die Welt des sicheren Informationsmanagements von Microsoft Azure - dem Azure Key Vault. In dieser digitalen Ära, in der Datenverletzungen und Sicherheitsbedenken zum Alltag jeder Online-Unternehmung gehören, ist eine bombensichere Methode zur Verwaltung sensibler Informationen entscheidend. Als Entwickler liegt es in unserer Verantwortung sicherzustellen, dass unsere Anwendungen nicht nur robust und effizient, sondern auch sicher und vertrauenswürdig sind. Dieser Blog soll Sie auf einer Reise durch die Integration von Azure Key Vault in Ihr .NET Core Web API-Projekt begleiten und sicherstellen, dass Ihre Geheimnisse genau das bleiben – geheim.</p>
<h2>Aber zuerst, was ist Azure Key Vault?</h2>
<p>Azure Key Vault ist ein Cloud-Service von Microsoft Azure, der speziell zum Schutz kryptografischer Schlüssel und Geheimnisse (wie Verbindungszeichenfolgen, API-Schlüssel oder andere sensible Daten) entwickelt wurde. Denken Sie daran wie an einen Banktresor, in dem Sie Ihre wertvollsten digitalen Vermögenswerte aufbewahren können. Anstatt diese Geheimnisse direkt in Ihren Code einzubetten, was zu möglichen Gefährdungen führen kann, bietet Azure Key Vault ein zentralisiertes und sicheres Speichersystem. Auf diese Weise können nur Anwendungen und Benutzer mit den entsprechenden Berechtigungen darauf zugreifen.</p>
<p>Bleiben Sie bei uns, während wir durch die Einrichtung, Feinheiten und Best Practices der Einbindung von Azure Key Vault in Ihre .NET Core Web API-Projekte navigieren. Ob Sie ein erfahrener Azure-Entwickler sind oder gerade erst in das weite Meer des Cloud-Computing eintauchen, dieser Blog soll Erkenntnisse und Schritte bieten, um die Sicherheit Ihrer digitalen Lösungen zu stärken.</p>
<h2>Erste Schritte & Voraussetzungen</h2>
<h3>Voraussetzungen</h3>
<ul>
  <li>Grundlegendes Verständnis für C#.</li>
  <li>Grundlegende Erfahrung mit Microsoft Visual Studio.</li>
  <li>Grundlegende Erfahrung mit Microsoft Azure.</li>
</ul>
<h3>Erste Schritte</h3>
<p>Wir beginnen mit der Erstellung eines .NET Core-Projekts in Microsoft Visual Studio. Zu Demonstrationszwecken verwenden wir die gute alte Wettervorhersage-Web-API-Vorlage von Microsoft.
</p>
<p></p>
<ol>
  <li>In den Projektvorlagen suchen Sie nach "API" und wählen die Option "ASP.NET Core Web API" aus.</li>
  <li>Benennen Sie das Projekt; Ich habe meins "AzureKeyVaultExample" genannt.</li>
  <li>Im zusätzlichen Informationsfenster lassen Sie alles, was vorausgewählt ist, und klicken auf "Erstellen".</li>
</ol>
<p>Mit unserem Demo-Projekt nun eingerichtet, ist unser nächster Schritt die Bereitstellung auf Microsoft Azure. Diese Bereitstellung ist nicht nur eine Formalität. Durch die Veröffentlichung der Anwendung können wir sie in eine Ressourcengruppe auf Azure integrieren. Darüber hinaus ist es, wenn Sie Azure Key Vault nutzen möchten, eine Voraussetzung, dass Ihre Anwendung innerhalb von Azure registriert ist. Befolgen Sie diese Schritte, um Ihre Anwendung auf Azure bereitzustellen:</p>

<h3>Bereitstellung in Azure</h3>
<p>Klicken Sie mit der rechten Maustaste auf die Hauptprojektdatei und wählen Sie "Veröffentlichen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-1.png"></img>
<p>Als Nächstes klicken Sie auf "Ein Veröffentlichungsprofil hinzufügen", wählen "Azure" und klicken auf "Weiter".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-2.png"></img>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-2.png"></img>
<p>Klicken Sie nun auf "Azure App Service (Windows)" und danach auf "Weiter".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-3.png"></img>
<p>Klicken Sie auf "Neu erstellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-4.png"></img>
<p>Im neuen App-Service-Fenster werden wir eine neue Ressourcengruppe erstellen.</p>
<p>Wählen Sie zunächst das Azure-Abonnement, das Sie verwenden möchten.</p>
<p>Als Nächstes klicken Sie im Abschnitt "Ressourcengruppe" auf "Neu".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-5.png"></img>
<p>Benennen Sie die Ressourcengruppe nach Belieben. Ich habe meine "RG-Azure-Key-Vault-Demo" genannt. Für meine Objekte in Azure verwende ich gerne Abkürzungen vor dem Namen, um sie leichter erkennen zu können. In diesem Fall steht "RG" für "Ressourcengruppe".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-6.png"></img>
<p>Klicken Sie nun im Abschnitt "Hosting-Plan" auf "Neu".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-7.png"></img>
<p>Im Hosting-Plan-Fenster treffen Sie Ihre Auswahl und klicken auf "Weiter", wenn Sie fertig sind.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-8.png"></img>
<p>Mit dem neuen App-Service können wir nun auf "Erstellen" klicken.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-9.png"></img>
<p>Im Veröffentlichungsfenster wählen Sie den neuen App-Service, den wir gerade erstellt haben, und klicken auf "Weiter".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-10.png"></img>
<p>Nachdem Sie auf "Weiter" geklickt haben, gelangen Sie zum API-Management-Bildschirm (der Tab ist in Gelb hervorgehoben). Wir werden für dieses Projekt eine neue erstellen, klicken Sie also auf "Neu erstellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-11.png"></img>
<p>Im neuen API-Management-Bildschirm werden Sie aufgefordert, einen Namen anzugeben. Geben Sie einen Namen an der unten in Gelb hervorgehobenen Stelle ein. Nach diesem Schritt wählen Sie im Abschnitt "Ressourcengruppe" die neue Ressourcengruppe, die wir gerade erstellt haben. Nach Abschluss dieses Schritts klicken Sie im Abschnitt "API Management-Dienst" auf "Neu".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-12.png"></img>
<p>Geben Sie dem neuen API-Management-Dienst einen geeigneten Namen und stellen Sie die Optionen in den anderen Abschnitten nach Ihren Bedürfnissen ein. Klicken Sie zum Abschluss auf "Ok".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-13.png"></img>
<p>Wir werden nun zum vorherigen Fenster zurückgeleitet. Für den Moment können wir den Abschnitt "API-URL-Suffix" ignorieren und auf "Erstellen" klicken. Dies wird einige Momente dauern, also fühlen Sie sich frei, währenddessen einen Tee oder Kaffee zu machen! ☕️.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-14.png"></img>
<p>Als Nächstes klicken Sie unter der neuen Ressourcengruppe und dem neuen API-Management-Dienst, den wir gerade erstellt haben, auf die neue API und klicken auf "Fertig stellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-15.png"></img>
<p>Schließlich sind wir jetzt bereit, auf Azure zu veröffentlichen. Klicken Sie auf "Veröffentlichen", um fortzufahren.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/publish-step-16.png"></img>
<p>Nach erfolgreicher Veröffentlichung wird ein Browserfenster mit der Wettervorhersagevorlage über eine Live-URL angezeigt. Wenn Sie "/WeatherForecast" an das Ende der URL anhängen, können Sie die JSON-Antwort über den vordefinierten "Get"-Controller-Endpunkt sehen.</p>
<p>Bitte beachten Sie, dass diese URL live ist und Sie sie möglicherweise mit IP-Beschränkungen absichern möchten, damit Sie keinen unerwünschten Datenverkehr erhalten und Datenverbrauchskontingente erreichen.</p>
<p>Mit dem nun in Azure veröffentlichten Visual Studio-Projekt können wir die Anwendung jetzt registrieren, damit der Dienst beim Konfigurieren von Azure Key Vault von unserer neu bereitgestellten Anwendung Kenntnis hat.</p>
<h2>Anwendung registrieren</h2>
<p>Wenn Sie bereits im Azure-Portal angemeldet sind, navigieren Sie zu "App-Registrierungen". Wenn Sie nicht sicher sind, wie Sie es finden, suchen Sie einfach nach "App-Registrierungen" in der oberen Suchleiste.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-1.png"></img>
<p>Im Menü "App-Registrierungen" klicken Sie auf "Neue Registrierung".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-2.png"></img>
<p>Geben Sie der App-Registrierung einen Namen und lassen Sie die übrigen Details, wie sie sind. Klicken Sie auf "Registrieren". Sie können die restlichen Optionen auf ihren Standardwerten belassen.</p>

<p>Das ist es für diesen Abschnitt im Moment. Wir werden später in diesem Blog zur Anwendungsregistrierung zurückkehren, um einen Clientgeheimniswert zu erstellen und die relevanten Konfigurationsparameter in unser Visual Studio-Projekt einzufügen.</p>
<h2>Schlüsseltresor Konfiguration</h2>
<p>In diesem Abschnitt werden wir einen Azure Schlüsseltresor erstellen und einen geheimen Wert setzen, der aus dem Schlüsseltresor abgerufen werden kann.</p>
<p>Navigieren Sie zu "Schlüsseltresore" im Azure-Portal oder geben Sie "Schlüsseltresore" in die Haupt-Suchleiste ein.</p>

<h3>Erstellung des Azure Schlüsseltresors</h3>
<p>Im Menü "Azure Schlüsseltresore" klicken Sie auf "Erstellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-1.png"></img>
<p>Im nächsten Bildschirm wählen Sie das Abonnement, das Sie verwenden möchten. Wählen Sie die neu erstellte Ressourcengruppe, die wir beim Veröffentlichen der Anwendung aus Visual Studio erstellt haben. Geben Sie dem neuen Schlüsseltresor einen Namen und wählen Sie Ihre gewünschte Region. Wenn Sie mit den restlichen Optionen zufrieden sind, klicken Sie auf "Überprüfen und erstellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-2.png"></img>
<p>Im Bildschirm "Überprüfen & Erstellen" klicken Sie auf "Erstellen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-3.png"></img>
<h3>Konto RBAC Rolle</h3>
<p>Bevor wir den geheimen Schlüssel und Wert erstellen, müssen wir eine neue RBAC-Rolle setzen, die Ihr Azure-Konto als Administrator im neu erstellten Schlüsseltresor zuweist. Um dies zu tun, klicken Sie im Hauptmenü in der linken Seitenleiste auf "Zugriffssteuerung (IAM)".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-1.png"></img>
<p>Im folgenden Bildschirm klicken Sie auf "Rollenzuweisung hinzufügen".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-2.png"></img>
<p>Im Bildschirm "Rollenzuweisung" suchen Sie in der Suchleiste nach "Key Vault Administrator", wählen Sie die Option "Key Vault Administrator" und klicken Sie auf "Weiter".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-3.png"></img>
<p>Im folgenden Bildschirm klicken Sie auf "Mitglieder auswählen" und suchen nach dem Namen oder der E-Mail, die mit Ihrem Azure-Konto verknüpft ist. Nach der Auswahl Ihres Kontos klicken Sie auf "Weiter".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/rbac-role/azure-key-vaults-rbac-step-4.png"></img>
<p>Als Nächstes klicken Sie auf "Überprüfen + Zuweisen". Danach werden Sie zum Hauptmenü des Schlüsseltresors zurückgeleitet.</p>

<h3>Erstellung des geheimen Schlüssels und Werts</h3>
<p>Es ist nun an der Zeit, den geheimen Schlüssel und Wert zu erstellen. Im Hauptmenü des Schlüsseltresors klicken Sie auf die Option "Geheimnisse" in der linken Seitenleiste.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-4.png"></img>
<p>Im folgenden Fenster klicken Sie auf "Generieren / Importieren".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-5.png"></img>
<p>Im Bildschirm "Ein Geheimnis erstellen" geben Sie dem Geheimnis einen Schlüsselwert. Dieser Schlüsselwert wird verwendet, wenn der geheime Wert aus dem Schlüsseltresor abgerufen wird. Setzen Sie den geheimen Wert und klicken Sie auf "Erstellen". Alle anderen Optionen können bei ihren Standardeinstellungen bleiben.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-6.png"></img>
<p>Nach dem Klicken auf "Erstellen" werden Sie zum Geheimnismenü des Schlüsseltresors weitergeleitet. Eine Meldung sollte anzeigen, dass das Geheimnis erfolgreich erstellt wurde.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-key-vaults-steps/azure-key-vaults-step-7.png"></img>
<h2>Abruf des geheimen Werts aus dem Azure Schlüsseltresor</h2>
<p>Jetzt, da wir den Schlüsseltresor der Anwendung konfiguriert haben, können wir beginnen, den geheimen Wert über das zuvor erstellte und auf Azure bereitgestellte Visual Studio-Projekt zuzugreifen.</p>
<p>Um Azure Schlüsseltresore in unserer Anwendung zu verwenden, müssen wir einige Pakete installieren. Kehren Sie zum Visual Studio-Projekt zurück, das wir zuvor auf Microsoft Azure bereitgestellt haben, und öffnen Sie den NuGet-Paketmanager.</p>
<p>Wählen Sie mit der "Durchsuchen"-Registerkarte aus, suchen Sie nach "Azure.Identity". Wenn Sie die Option finden, wählen Sie sie aus. Im rechten Fenster wählen Sie die "Neueste stabile" Version und klicken Sie auf "Installieren".</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-1.png"></img>
<p>Nach der Installation des obigen Pakets geben Sie "Azure.Security.KeyVault.Secrets" in die Suchleiste ein. Installieren Sie die neueste stabile Paketversion.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-2.png"></img>
<p>Jetzt müssen wir unser letztes Paket installieren. Geben Sie in die Suchleiste "Azure.Extensions.AspNetCore.Configuration.Secrets" ein, wählen Sie dann aus und installieren Sie die neueste stabile Version des Pakets.</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-3.png"></img>

<p>Als Nächstes beginnen wir, unsere Schlüsseltresor-Konfigurationsparameter in unserer Visual Studio-App zu speichern.</p>
<p>In Ihrem Projektverzeichnis navigieren Sie zur Datei appsettings.json und fügen das Folgende hinzu:</p>
<p>Navigieren Sie im Hauptverzeichnis Ihres Projekts zur Datei appsettings.json und fügen Sie Folgendes hinzu:</p>
         <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`"AzurekeyVaultConfig": {
   "KVUrl“: "“,
   "KVClientId“: "“,
   "KVTenantId“: "“,
   "KVClientSecretId“: "“
  },
`}</code></pre></div>
   <p>Um die leeren Werte, die wir gerade hinzugefügt haben, aufzufüllen, müssen wir in den nächsten Schritten zu unserer zuvor abgeschlossenen Anwendungsregistrierung in Azure zurückkehren, um ein neues Client-Geheimnis zu erstellen.</p>
<p>Navigieren Sie im Azure-Portal zu „App-Registrierungen“. Suchen Sie die Anwendung, die Sie zuvor registriert haben, und klicken Sie darauf, um zum Übersichtsbildschirm zu gelangen.</p>
         <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-4.png"></img>
         <p>Klicken Sie im Hauptmenü der App-Registrierung in der linken Seitenleiste auf „Zertifikate & Geheimnisse“. Klicken Sie anschließend auf „Neues Client-Geheimnis“.</p>
         <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-3.png"></img>
         <p>Benennen Sie im neuen Fenster, das erscheint, den neuen Client-Schlüssel. Stellen Sie den Ablauf auf den gewünschten Wert ein; Ich habe den Standardwert von 180 Tagen beibehalten. Klicken Sie auf „Hinzufügen“.</p>
         <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-4.png"></img>
         <p>Nachdem das neue Client-Geheimnis erstellt wurde, notieren Sie sich den Geheimwert in der Spalte „Wert“ der Tabelle:</p>
         <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/azure-app-registration-steps/azure-key-vault-app-reg-step-5.png"></img>
         <p>Kehren Sie zur Datei appsettings.json zurück und legen Sie diesen Wert als „KVClientSecretId“-Schlüssel fest:</p>
         <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`"AzurekeyVaultConfig": {
   "KVUrl“: „“,
   "KVClientId“: „“,
   "KVTenantId“: „“,
   "KVClientSecretId“: "Client-Secret-Id-Goes-Here“
  },
`}</code></pre></div>
       <p>Navigieren Sie als Nächstes zum Übersichtsreiter der App-Registrierung. Wir müssen uns die Parameter „Anwendungs-ID (Client)“ und „Verzeichnis-ID (Mandant)“ notieren:</p>
       <img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-6.png"></img>
       <p>Legen Sie in der Datei appsettings.json im Visual Studio-Projekt die „Anwendungs-ID (Client)“ als Wert für „KVClientId“ und die „Verzeichnis-ID (Mandant)“ als Wert für „KVTenantId“ fest:</ p>
       <p></p>

       <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`"AzurekeyVaultConfig": {
   "KVUrl“: „“,
   "KVClientId“: "Client-ID-Goes-Here,
   "KVTenantId“: "Tenant-ID-Goes-Here,
   "KVClientSecretId“: "Client-Secret-Id-Goes-Here“
  },
`}</code></pre></div>
    <p>Um schließlich den Wert für den Schlüssel „KVUrl“ zuzuweisen, müssen wir die URL des neuen Azure Key Vault abrufen, den wir zuvor erstellt haben.</p>
<p>Navigieren Sie dazu zur Key Vaults-Konsole im Azure-Portal. Suchen Sie im Fenster „Key Vaults“ nach dem Schlüsseltresor, den Sie zuvor erstellt haben, und klicken Sie dann darauf. Nach Abschluss dieses Schritts werden Sie zum Übersichtsbildschirm des Schlüsseltresors weitergeleitet:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-7.png"></img>
<p>Kopieren Sie den Wert des Parameters „Vault URI“ und fügen Sie ihn als Wert für den „KVUrl“-Schlüssel in die Datei appsettings.json von Visual Studio ein:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-8.png"></img>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`"AzurekeyVaultConfig": {
   "KVUrl“: „Web-API-URL-Goes-Hier“,
   "KVClientId“: "Client-ID-Goes-Here,
   "KVTenantId“: "Tenant-ID-Goes-Here,
   "KVClientSecretId“: "Client-Secret-Id-Goes-Here“
},
`}</code></pre></div>
<h3>Programm.cs ändern</h3>
<p>Da wir nun unsere Konfigurationsparameter eingerichtet haben, ändern wir die Datei „program.cs“ im Hauptverzeichnis des Visual Studio-Projekts. Dadurch können wir die neu hinzugefügten Schlüsseltresorkonfigurationen innerhalb des Haupteinstiegspunkts der Anwendung laden.</p>
<p>Fügen Sie die folgenden Zeilen zu program.cs hinzu:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`string keyVaultUrl = builder.Configuration["AzurekeyVaultConfig:KVUrl"];
string tenantId = builder.Configuration["AzurekeyVaultConfig:KVTenantId"];
string clientId = builder.Configuration["AzurekeyVaultConfig:KVClientId"];
string clientSecretId = builder.Configuration["AzurekeyVaultConfig:KVClientSecretId"];

builder.Configuration.SetBasePath(builder.Environment.ContentRootPath)
    .AddJsonFile("appsettings.json", optional: false, reloadOnChange: true)
    .AddEnvironmentVariables();

var clientSecretCredential = new ClientSecretCredential(tenantId, clientId, clientSecretId);
var secretClient = new SecretClient(new Uri(keyVaultUrl), clientSecretCredential);
`}</code></pre></div>
<p>Als nächstes fügen wir unserer Datei program.cs eine Abhängigkeitsinjektionszeile hinzu. Dadurch kann unser Controller auf unsere Repository-Methoden zugreifen, ohne jedes Mal, wenn ein Benutzer unseren Controller-Endpunkt erreicht, eine neue Instanz unserer Repository-Klasse erstellen zu müssen. Um dies zu erreichen, fügen Sie die folgenden Codezeilen unter der kommentierten Zeile „//Dienste zum Container hinzufügen“ hinzu:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`builder.Services.AddScoped&lt;IRepository, Repository&gt;();
`}</code></pre></div>
  <p>Bitte beachten Sie, dass das Hinzufügen der obigen Zeilen vorübergehend zu einem Fehler führt, da die Klasse und die Schnittstelle noch nicht erstellt wurden. Wir werden sie in Kürze hinzufügen.</p>
  <p>Wenn Visual Studio nach dem Hinzufügen der obigen Codezeilen nicht automatisch die erforderlichen Namespaces importiert, fügen Sie schließlich die folgenden Importanweisungen am Anfang der Datei hinzu:</p>
  
  <div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`using Azure.Identity;
using Azure.Security.KeyVault.Secrets;
`}</code></pre></div>
<p>Ihre gesamte program.cs-Datei sollte jetzt etwa so aussehen:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`using Azure.Identity;
using Azure.Security.KeyVault.Secrets;

var builder = WebApplication.CreateBuilder(args);

string keyVaultUrl = builder.Configuration["AzurekeyVaultConfig:KVUrl"];
string tenantId = builder.Configuration["AzurekeyVaultConfig:KVTenantId"];
string clientId = builder.Configuration["AzurekeyVaultConfig:KVClientId"];
string clientSecretId = builder.Configuration["AzurekeyVaultConfig:KVClientSecretId"];
builder.Configuration.SetBasePath(builder.Environment.ContentRootPath)
    .AddJsonFile("appsettings.json", optional: false, reloadOnChange: true)
    .AddEnvironmentVariables();

var clientSecretCredential = new ClientSecretCredential(tenantId, clientId, clientSecretId);

var secretClient = new SecretClient(new Uri(keyVaultUrl), clientSecretCredential);

// Add services to the container.
builder.Services.AddScoped&lt;IRepository, Repository&gt;();

builder.Services.AddControllers();
// Learn more about configuring Swagger/OpenAPI at https://aka.ms/aspnetcore/swashbuckle
builder.Services.AddEndpointsApiExplorer();
builder.Services.AddSwaggerGen();

var app = builder.Build();

// Configure the HTTP request pipeline.
if (app.Environment.IsDevelopment())
{
    app.UseSwagger();
    app.UseSwaggerUI();
}

app.UseHttpsRedirection();

app.UseAuthorization();

app.MapControllers();

app.Run();
`}</code></pre></div>
<h3>Implementieren der Repository- und Schnittstellenmethoden</h3>
<p>Im letzten Schritt der Änderung von program.cs haben wir eine Abhängigkeitsinjektionszeile hinzugefügt. Wir werden nun damit fortfahren, das Repository und die Schnittstelle zu erstellen, die dieser Codezeile entsprechen.</p>

<p>Erstellen Sie einen Ordner im Hauptverzeichnis Ihres Visual Studio-Projekts und nennen Sie ihn „Repositories“. Erstellen Sie einen weiteren Ordner und nennen Sie ihn „Interfaces“.</p>
<p>Erstellen Sie im Ordner „Repositories“ eine neue Klassendatei mit dem Namen „Repository.cs“.</p>
<p></p>
<p>Kehren Sie zur Klassendatei „Repository.cs“ zurück und stellen Sie sicher, dass sie von der gerade erstellten „IRepository“-Schnittstelle erbt, etwa so:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class Repository : IRepository
{

}
`}</code></pre></div>
<p>Nachdem wir das Repository und die Schnittstelle hinzugefügt haben, können wir sie nun in unserer Datei „program.cs“ referenzieren. Fügen Sie oben in der Datei die folgenden Importanweisungen hinzu und achten Sie darauf, dass Sie den Namen Ihres Projekts verwenden:</p>
<div class="code-block"><pre><code class="language-csharp">{`using AzureKeyVaultExample.Interface;
using AzureKeyVaultExample.Repository;
`}</code></pre></div>
<p>Navigieren Sie zurück zur neu erstellten Datei „Repository.cs“ und deklarieren Sie ein Feld vom Typ IConfiguration. Initialisieren Sie den Konstruktor wie folgt:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public class Repository : IRepository
{
    private readonly IConfiguration _configuration;
    public Repository(IConfiguration configuration)
    {
        _configuration = configuration;
    }
}
`}</code></pre></div>
<p>Der Zweck dieses Feldes besteht darin, uns das Lesen verschiedener Werte aus der Datei appsettings.json zu ermöglichen, die wir für die Schlüsseltresorkonfiguration benötigen.</p>

<p>Als nächstes fügen wir in unserer Repository-Klassendatei eine Methode hinzu, die den geheimen Wert abruft, den wir in Azure Key Vaults erstellt haben. Diese Methode wird später in unserer Controller-Klassendatei verwendet.</p>

<p>Fügen Sie die folgenden Codezeilen zu „Repository.cs“ hinzu:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public string GetSecretValue(string secretName)
{
    string keyVaultUrl = _configuration["AzurekeyVaultConfig:KVUrl"];

    var client = new SecretClient(new Uri(keyVaultUrl), new DefaultAzureCredential());
            
    try
    {
        KeyVaultSecret secret = client.GetSecret(secretName);
        return secret.Value;
    }
    catch (Exception ex)
    {
        // Handle the exception as appropriate for your application.
        Console.WriteLine($"Error retrieving secret: {ex.Message}");
        return null;
    }
}
`}</code></pre></div>
<p>Im obigen Codeausschnitt wird die URL für den Schlüsseltresor aus unserer Datei appsettings.json gelesen. Der geheime Name wird von unserem Controller-Endpunkt übergeben, der diese Methode aufruft, die wir in Kürze implementieren werden.</p>

<p>Navigieren Sie als Nächstes zurück zur Schnittstellendatei „IRepository.cs“ und stellen Sie sicher, dass Sie die neue Methode hinzufügen, die wir in „Repository.cs“ implementiert haben. Dadurch wird sichergestellt, dass der Repository-Vertrag eingehalten wird:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`public interface IRepository
{
    public string GetSecretValue(string secretName);
}
`}</code></pre></div>
<p>Da es sich hierbei um eine Demo zur Verwendung von Azure Key Vaults handelt, erstellen wir keine Wrapper-Klasse für die Azure Key Vaults-Klassenobjekte von Microsoft. In einer Produktionsanwendung wäre es jedoch vorteilhaft, eine Wrapper-Klasse für kontrollierte Interaktion, Testzwecke und Anpassungsfähigkeit zu verwenden. Seien Sie gespannt auf einen weiteren Blog, in dem wir die Implementierung von Azure Key Vaults in einem TDD-Ansatz besprechen.</p>

<h3>Ändern des Controllers</h3>

<p>In diesem Abschnitt ändern wir die Datei „WeatherForecastController.cs“, sodass der Wettervorhersageendpunkt den in Azure Key Vaults gespeicherten geheimen Wert abruft, sofern der richtige Schlüsselwert angegeben wird.</p>

<p>Öffnen Sie „WeatherForecastController.cs“ und fügen Sie den folgenden Code hinzu. Dieser Code ermöglicht es dem Konstruktor der Klasse, die zuvor erstellte Repository-Abhängigkeitsinjektion zusammen mit Felddeklarationen für das Repository und die Konfigurationsschnittstellen zu verwenden:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`private readonly ILogger&lt;WeatherForecastController&gt; _logger;
private readonly IRepository _repository;
private readonly IConfiguration _configuration;

public WeatherForecastController(ILogger&lt;WeatherForecastController&gt; logger, IRepository repository, IConfiguration configuration)
{
    _logger = logger;
    _repository = repository;
    _configuration = configuration;
}
`}</code></pre></div>

<p>Next, navigate to the "Get" method in the controller and replace the existing method with the following code:</p>
<div class="code-block"><pre class="language-csharp"><code class="language-csharp">{`[HttpGet(Name = "GetWeatherForecast")]
public IEnumerable&lt;WeatherForecast&gt; Get(string secretName)
{
    if (_repository.GetSecretValue(secretName) != null)
    {
        return Enumerable.Range(1, 5).Select(index =&gt; new WeatherForecast
        {
            Date = DateTime.Now.AddDays(index),
            TemperatureC = Random.Shared.Next(-20, 55),
            Summary = Summaries[Random.Shared.Next(Summaries.Length)]
        })
        .ToArray();
    }
    else
    {
        return null;
    }
}
`}</code></pre></div>
<h3>Ausführen der Anwendung</h3>
<p>Nachdem der „Get“-Controller aktualisiert wurde, ist es an der Zeit zu testen, ob das Abrufen des Geheimnisses wie erwartet funktioniert. Notieren Sie sich unbedingt den Schlüssel des Geheimnisses, das Sie zuvor im Azure Key Vault erstellt haben, damit Sie ihn als Argument an den Controller-Endpunkt übergeben können:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-9.png"></img>

<p>Führen Sie die Anwendung aus. Sobald die Swagger-Hauptseite geladen ist, klicken Sie auf die Schaltfläche „Ausprobieren“:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-10.png"></img>

<p>Stellen Sie danach sicher, dass Sie den korrekten Namen des Schlüssels für das Geheimnis eingeben, das wir im Schlüsseltresor erstellt haben, und klicken Sie auf „Ausführen“. Innerhalb weniger Sekunden werden Ihnen die JSON-Ergebnisse der Wettervorhersage zurückgegeben. Wenn Sie einen ungültigen Schlüsselnamen angeben, sollten Sie die Fehlermeldung 204 erhalten:</p>
<img src="https://kmc-technologies.ltd/Blogs/Azure-Key-Vault-Blog/visual-studio-steps/key-vault-access/key-vault-access-step-11.png"></img>

<p>Damit ist dieser Blogbeitrag abgeschlossen. In einem zukünftigen Blog werde ich dieses Setup zusammen mit einigen Best Practices und Prinzipien für die Codierung stärker auf TDD ausrichten. Bis dahin, auf Wiedersehen! :)</p>

<br></br>

  </div>),

  },
  {
    link: "/blog/ci-cd-pipeline-iis-dotnet-web-api",
    img: "CI-CD-pipeline-blog-image-title.svg",
    title: "CI CD Pipeline - IIS .NET Web API Application On An Azure VM using GitHub Workflows & Power Shell Script Automation",
    titleDe: "CI CD Pipeline - IIS .NET Web-API-Anwendung auf einer Azure-VM mit GitHub-Workflows und PowerShell-Skriptautomatisierung",
    desc: "Implementing a CI CD pipeline on an Azure VM running a web API on an IIS server. VM deployed and configured via powershell automation scripts",
    descDe: "Implementierung einer CI CD Pipeline auf einer Azure-VM, die eine Web-API auf einem IIS-Server ausführt. VM wird über PowerShell-Automatisierungsskripte bereitgestellt und konfiguriert",
    date: "20th May, 2024",
    dateDe: "20. Mai, 2023",
    
    initialContent: (<div></div>)    
    ,
    content:(
    <div class="content-item">
        <p></p>
        <p><a href="https://github.com/kieron-cairns/IIS-.NET-Web-API-Azure-VM-CI-CD-Pipeline- " rel="noopener noreferrer" target="_blank">GitHub link to powershell script &amp; GitHub workflow yaml configuration file</a></p>
        <p></p>
        <p>Greetings, in this post, we will be delving into the process of setting up a CI CD pipeline via GitHub workflow actions for a .NET Web API application running on an Azure Virtual Machine remotely using IIS. Some reasons you may want to host a web API on a VM rather than Azure’s default web API option include having full control over the environment, advanced networking and enhanced security controls.&nbsp;</p>
        <p></p>
        <p>This post will outline the following;</p><p></p>
        <ol><li>Creating a SSH key pair to connect to the Azure virtual machine.&nbsp;</li>
        <li>Creating an Azure VM</li>
        <li>Creating a GitHub workflow file to deploy a .NET web API project to the virtual machine running IIS</li></ol><p>
        </p><p>Deployment of the virtual machine and its necessary dependencies will be executed via a windows power shell script to facilitate automation.</p>
        <p>Let’s get started!</p>
        
        <p><strong>If you would like to follow this tutorial, you will need to install the Microsoft Azure CLI and login in to your Azure account sign the az login command:&nbsp;</strong></p><p><div class="code-block"><pre><code class="language-c-sharp">{`az login
`}</code></pre></div></p><h2>1. Creating an SSH key pair</h2><p></p><p>Before we begin, we will generate an SSH key pair on a local machine, this key pair will be used in order to securely transfer files during any CI CD processes when changes are made to the .NET web API code. In order to generate this key pair, use the following command:&nbsp;</p><p></p><div class="code-block"><pre><code class="language-c-sharp">{`ssh-keygen -t rsa -b 2048
`}</code></pre></div><p></p><p>After entering this command, you will be prompted to save the key pair to a file. For now, this can be left empty by simply pressing enter. This will save the key pair to the default location which is the .ssh directory. As well as this, you will be requested to enter a password, this can also be left empty.</p><p></p><p>After the key pair has been generated, we can now create a Windows powershell script that will deploy a virtual machine within Microsoft Azure. Let’s start writing the script.</p>
        
        <h2><strong>2. Powershell Automation Virtual Machine Deployment</strong></h2><p></p><p>Create a new Windows powers script with the file extension .ps1 and each of the following code snippets to the script:&nbsp;</p><p></p><h3><strong>2.1.1. Create a New Resource Group:</strong></h3><p></p><div class="code-block"><pre><code class="language-c-sharp">{`New-AzResourceGroup -Name 'RgAzVMWebAPI' -Location 'ukwest'
`}</code></pre></div><p></p><p>The above line creates a new Azure Resource Group named 'RgAzVMWebAPI' in the 'UK West' Azure region. Resource groups in Azure are containers that hold related resources for an Azure solution—in this case, the VM and associated resources.</p>

<h3><strong>2.1.2 Secure Password Creation:</strong></h3><p></p><div class="code-block"><pre><code class="language-c-sharp">{`powershell

$securePassword = ConvertTo-SecureString "Password!234" -AsPlainText -Force
`}</code></pre></div><p></p><p>Here, we're converting a plain text password into a secure string. This secure string is encrypted, making it safer to use in scripts and processes. The `-Force` parameter is used to bypass the confirmation prompt that would typically appear for this kind of operation.Obviously in an production environment, use a much stronger password!</p>

<h3><strong>2.1.3. Credential Object Creation:</strong></h3><p></p><div class="code-block"><pre><code class="language-c-sharp">{`powershell

$credential = New-Object System.Management.Automation.PSCredential ("azureuser", $securePassword)
`}</code></pre></div><p></p><p>Using the secure password, a `PSCredential` object is created with the username 'azureuser'. This credential object is used to authenticate with Azure services securely.</p>


<h3><strong>2.1.4. Virtual Machine Deployment:</strong></h3><p></p><div class="code-block"><pre><code class="language-c-sharp">{`$result1 = New-AzVm 
 -ResourceGroupName 'RgAzVMWebAPI' 
 -Name 'AzVMWebAPI' 
 -Location 'ukwest' 
 -Image 'MicrosoftWindowsServer:WindowsServer:2022-datacenter-azure-edition:latest' 
 -Size 'Standard_B2s' 
 -VirtualNetworkName 'AzVMWebAPIVNet' 
 -SubnetName 'AzVMWebAPISubnet' 
 -SecurityGroupName 'AzVMWebAPINSG' 
 -PublicIpAddressName 'AzVMWebAPIPublicIP' 
 -OpenPorts 80,3389,22 

`}</code></pre></div><p></p><p>This multi-line command (`New-AzVm`) initiates the creation of a new VM. It specifies various parameters like the resource group name, VM name, location, and the image to use (Windows Server 2022 Datacenter Azure Edition). It also defines the VM size (`Standard_B2s`), networking details (like VNet and subnet names), and security settings, including a network security group and public IP address. The `-OpenPorts` parameter is used to ensure that the VM can receive traffic on specific ports: 80 (HTTP), 3389 (RDP for remote desktop access), and 22 (SSH).</p><p></p><p>The backtick (`) at the end of each line is the line continuation character in PowerShell, allowing the command to span multiple lines for better readability.</p><p></p><p>The result of the VM creation command is stored in the variable `$result1`, which can be used for further processing or validation checks in subsequent script sections.</p><p></p><p>The next part of the script will consist of various commands that will install SSH onto the VM along with specifying the file location of the public key of the Ssh key we earlier generated on our local machine. This public key will be used later when verifying known SSH hosts on our remote VM. Please note, in an productuion environment, it is better practice to use port 443 over 80 for a secure connection. Port 80 is used in this article for demonstration purposes.</p>

<h2><strong>2.2 Open SSH Installation</strong></h2>

<p>After the VM is provisioned, the next step is to configure Secure Shell (SSH) access. SSH is a protocol that provides a secure channel over an unsecured network in a client-server architecture, allowing for secure login from one computer to another.</p>

<h3>2.2.1. Public Key Retrieval:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicKeyPath = 'C:\\Users\\kieroncairns\\.ssh\\id_rsa.pub'
$publicKey = Get-Content $publicKeyPath -Raw | Out-String | ForEach-Object { $_.TrimEnd() }
`}</code></pre></div>
<p>The script first defines the path to the public SSH key file. It then reads the content of this file and stores it in the `$publicKey` variable. This public key will be used for SSH authentication, enabling secure passwordless access to the VM.</p>


<h3>2.2.2. OpenSSH Installation Script:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installSsh = "@
    Add-WindowsCapability -Online -Name OpenSSH.Server
    Start-Service sshd
    Set-Service -Name sshd -StartupType 'Automatic'
"@`}</code></pre></div>
<p>Here, a multi-line string (a here-string in PowerShell terminology) is assigned to the `$installSsh` variable. This string contains commands to install the OpenSSH Server feature on the Windows VM, start the SSH service (`sshd`), and set it to start automatically with the system boot.</p>

<h3>2.2.3. Executing the Installation Script on the VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`try {
    Write-Output "Installing Open SSH on VM"
    Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installSsh
    Write-Output "Open SSH Installation Successful"
}
catch {
    Write-Output "Error Installing Open SSH"
}`}</code></pre></div>
<p>The script then tries to execute the OpenSSH installation commands on the VM using the `Invoke-AzVMRunCommand` cmdlet. This cmdlet allows you to run PowerShell scripts directly on Azure VMs. If the installation succeeds, it prints a success message. If any error occurs during this process, the catch block captures the error and prints an error message.</p>
<p>The use of `try-catch` blocks in PowerShell is an effective way to handle exceptions and errors in scripts. It ensures that the script can gracefully handle any issues that might occur during execution and provides clear output about the success or failure of the operation.</p>


<h2><strong>2.3 Logging onto the VM to create necessary admin user account directory&nbsp;</strong></h2>

<p>After the VM is provisioned, the next step is to configure Secure Shell (SSH) access. SSH is a protocol that provides a secure channel over an unsecured network in a client-server architecture, allowing for secure login from one computer to another.</p>

<h3>2.2.1. Public Key Retrieval:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicKeyPath = 'C:\\Users\\kieroncairns\\.ssh\\id_rsa.pub'
$publicKey = Get-Content $publicKeyPath -Raw | Out-String | ForEach-Object { $_.TrimEnd() }
`}</code></pre></div>
<p>The script first defines the path to the public SSH key file. It then reads the content of this file and stores it in the `$publicKey` variable. This public key will be used for SSH authentication, enabling secure passwordless access to the VM.</p>

<h3>2.2.2. OpenSSH Installation Script:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installSsh = "@
    Add-WindowsCapability -Online -Name OpenSSH.Server
    Start-Service sshd
    Set-Service -Name sshd -StartupType 'Automatic'
"@`}</code></pre></div>
<p>Here, a multi-line string (a here-string in PowerShell terminology) is assigned to the `$installSsh` variable. This string contains commands to install the OpenSSH Server feature on the Windows VM, start the SSH service (`sshd`), and set it to start automatically with the system boot.</p>

<h3>2.2.3. Executing the Installation Script on the VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`try {
    Write-Output "Installing Open SSH on VM"
    Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installSsh
    Write-Output "Open SSH Installation Successful"
}
catch {
    Write-Output "Error Installing Open SSH"
}`}</code></pre></div>
<p>The script then tries to execute the OpenSSH installation commands on the VM using the `Invoke-AzVMRunCommand` cmdlet. This cmdlet allows you to run PowerShell scripts directly on Azure VMs. If the installation succeeds, it prints a success message. If any error occurs during this process, the catch block captures the error and prints an error message.</p>
<p>The use of `try-catch` blocks in PowerShell is an effective way to handle exceptions and errors in scripts. It ensures that the script can gracefully handle any issues that might occur during execution and provides clear output about the success or failure of the operation.</p>


<h2>2.3 Logging onto the VM to create necessary admin user account directory</h2>

<p>The next part of the script will log in the admin user of the VM via SSH in order to create the user account upon the VM’s first use. As well as this, the public key that was generated earlier will be added to an authorized_keys SSH file that is present within the admin accounts user directory. This step will allow for key-based authentication via SSH that will be executed in the next segment of the script after this:</p>

<h3>2.3.1. Retrieve Public IP Address:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicIp = Get-AzPublicIpAddress -ResourceGroupName 'RgAzVMWebAPI' -Name 'AzVMWebAPIPublicIP'
$ipAddress = $publicIp.IpAddress
`}</code></pre></div>
<p>The script uses the `Get-AzPublicIpAddress` cmdlet to fetch the public IP address assigned to the VM. This IP address is crucial for making remote connections.</p>

<h3>2.3.2. Check IP Address Availability:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`if (-not $ipAddress) {
    throw "Failed to retrieve IP Address."
}
`}</code></pre></div>
<p>It checks if the IP address was successfully retrieved. If not, it throws an exception, effectively stopping the script and indicating a problem in the process.</p>

<h3>2.3.3. Display IP Address:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "VM Public IP Address: $ipAddress"
`}</code></pre></div>
<p>If the IP address is successfully retrieved, it is displayed to the user.</p>

<h3>2.3.4. Configure SSH for Remote Login:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$sshCommands = "@
echo 'Creating user profile on VM and adding public key to authorized_keys file...'
mkdir C:\\Users\\azureuser\\.ssh
echo $publicKey > C:\\Users\\azureuser\\.ssh\\authorized_keys
exit
"@`}</code></pre></div>
<p>The script prepares a string of SSH commands to create a `.ssh` directory in the Azure user's home directory and add the previously retrieved public key to the `authorized_keys` file. This setup allows for secure key-based authentication.</p>

<h3>2.3.5. Establish SSH Connection and Execute Commands:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$sshCommand = "ssh -o StrictHostKeyChecking=no azureuser@$ipAddress"
$fullCommand = "echo \`"$sshCommands\`" | $sshCommand"
Invoke-Expression $fullCommand
`}</code></pre></div>
<p>The script then constructs an SSH command to connect to the VM and pipes the SSH commands to be executed upon login. The use of `StrictHostKeyChecking=no` bypasses the host key verification; this is not recommended for production environments as it could make the connection vulnerable to man-in-the-middle attacks.</p>

<h3>2.3.6. Error Handling:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`} catch {
    Write-Error "An error occurred: $_"
}
`}</code></pre></div>
<p>The script is enclosed in a try-catch block to handle any potential errors during the SSH setup. If an error occurs, it is captured and printed out, and the script can perform any necessary cleanup.</p>

<h2>2.4 Disabling Password Authentication & Enabling Key Based Authentication Via SSH</h2>

<p>This part of the script is essential for ensuring that the VM can be securely managed via SSH without the need for password authentication, which is an important aspect of automating VM management. The script not only automates the deployment of the VM but also its initial configuration, reducing the manual steps required to get a VM up and running which will be a key aspect in the GitHub actions workflow CI CD yaml file.</p>

<h3>2.4.1. Defining the SSH Configuration File Path:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$filePath = "C:\\ProgramData\\ssh\\sshd_config"
`}</code></pre></div>
<p>The script sets the path to the SSH server configuration file, `sshd_config`, which contains settings that dictate how the SSH server behaves.</p>

<h3>2.4.2. Disabling Password Authentication:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disablePwdAuthentication = "@
(Get-Content $filePath) -replace '#PasswordAuthentication yes', 'PasswordAuthentication no' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>This block of code is designed to enhance security by disabling password authentication for SSH, forcing all users to connect using SSH keys instead. It does this by modifying the SSH configuration file to set `PasswordAuthentication` to `no`, and then it restarts the SSH service to apply the changes.</p>

<h3>2.4.3. Enabling Public Key Authentication:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$enablePubKeyAuthentication = "@
(Get-Content $filePath) -replace '#PubkeyAuthentication yes', 'PubkeyAuthentication yes' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>This code ensures that public key authentication is enabled, allowing users to authenticate using their SSH keys. It modifies the corresponding setting in the configuration file and restarts the SSH service.</p>

<h3>2.4.4. Disabling Match Group Admins:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disableMatchGroupAdmins = "@
(Get-Content $filePath) -replace 'Match Group administrators', '#Match Group administrators' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>In this step, the script comments out any specific settings that apply only to users in the `administrators` group. This is a way of ensuring that SSH configurations apply uniformly to all users.</p>

<h3>2.4.5. Disabling Admin Authorized Keys File:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disableAdminAuthorizedKeysFile = "@
(Get-Content $filePath) -replace 'AuthorizedKeysFile __PROGRAMDATA__/ssh/administrators_authorized_keys', '#AuthorizedKeysFile __PROGRAMDATA__/ssh/administrators_authorized_keys' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>This section of the script comments out the line that specifies a separate `authorized_keys` file for administrator users, ensuring that the same `authorized_keys` file is used for all users.</p>

<h3>2.4.6. Executing Configuration Changes:</h3>
<p>Each block of configuration changes is executed on the VM using the `Invoke-AzVMRunCommand` cmdlet. This cmdlet allows the script to run PowerShell commands on the remote VM. After each command block is run, there is an output to the user indicating the action that has been taken (e.g., "Disabled password authentication in sshd_config").</p>

<h3>2.4.7. Error Handling:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`} catch {
    Write-Output "Error Creating SSH Directory: $_"
}
`}</code></pre></div>
<p>The `try-catch` block encapsulates the commands to handle any errors that occur during the execution of the configuration changes. If an error occurs, it is caught, and a message is displayed to the user.</p>

<p>This segment is critical for securing the SSH server by implementing key-based authentication and disabling less secure password authentication. The script streamlines the process of hardening the SSH configuration, which is an important aspect of deploying a secure and production-ready VM in the cloud.</p>

<h2>2.5 .NET 8 Installation</h2>

<p>With the Azure VM now set up and secured, the next step in our deployment process is to install the necessary software for the .NET web API to run. In this case, we're focusing on installing the .NET 8 runtime, which is essential for running the web API project.</p>

<h3>2.5.1. Starting the Installation Process:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Starting .NET 8 Runtime Installation"
`}</code></pre></div>
<p>This line simply outputs a message to the console indicating that the .NET 8 runtime installation is about to start.</p>

<h3>2.5.2. Specifying the Installer URL:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$dotnetRuntimeInstallerUrl = "https://download.visualstudio.microsoft.com/download/pr/ab5e947d-3bfc-4948-94a1-847576d949d4/bb11039b70476a33d2023df6f8201ae2/dotnet-sdk-8.0.201-win-x64.exe"
`}</code></pre></div>
<p>Here we define the URL where the .NET 8 runtime installer can be downloaded. This URL points to the official Microsoft server hosting the installer package.</p>

<h3>2.5.3. Defining the Local Installer Path:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installerPath = "C:\\dotnet-sdk-8.0.201-win-x64.exe"
`}</code></pre></div>
<p>The script sets a local path on the VM where the .NET 8 runtime installer will be downloaded to.</p>

<h3>2.5.4. Downloading and Installing the .NET 8 Runtime:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installDotNetRuntime = "@
Invoke-WebRequest -Uri $dotnetRuntimeInstallerUrl -OutFile $installerPath
Start-Process -FilePath $installerPath -ArgumentList '/quiet', '/norestart' -Wait
Remove-Item -Path $installerPath -Force
"@`}</code></pre></div>
<p>This block of code is a multi-line PowerShell command that handles three tasks: downloading the installer, executing it with silent and no-restart arguments, and cleaning up by deleting the installer file.</p>

<h3>2.5.5. Executing the Installation on the VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installDotNetRuntime
`}</code></pre></div>
<p>This line runs the installation commands on the remote VM using the `Invoke-AzVMRunCommand` cmdlet, which allows us to execute PowerShell scripts on Azure VMs.</p>

<h3>2.5.6. Confirmation of Success:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output ".NET 8 Runtime Installation Successful"
`}</code></pre></div>
<p>Once the installation commands have been successfully executed, the script outputs a confirmation message.</p>

<h3>2.5.7. Error Handling:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`catch
{
    Write-Output "Error Installing .NET 8 Runtime: $_"
}
`}</code></pre></div>
<p>The `try-catch` block is used here to handle any exceptions that might occur during the installation process. If an error occurs, it is captured and an error message is displayed.</p>

<p>This segment ensures that the server environment is prepared with the necessary runtime for the .NET web API application. By scripting this installation, we automate what would otherwise be a manual and potentially error-prone process, resulting in a more efficient and reliable setup.</p>

<h2>2.6 .NET 8 Hosting Bundle Installation</h2>

<p>The script's last task is to install the .NET 8 Hosting Bundle, which is a set of components needed to host .NET applications on Windows servers, particularly in IIS (Internet Information Services). This is a critical step if the VM will serve as a web server for .NET web applications.</p>

<h3>2.6.1. Initiating Hosting Bundle Installation:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Starting .NET 8 Hosting Bundle Installation"
`}</code></pre></div>
<p>A message is displayed to indicate that the installation process for the .NET 8 Hosting Bundle is beginning.</p>

<h3>2.6.2. Hosting Bundle Installer URL:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$dotnetHostingBundleInstallerUrl = "https://download.visualstudio.microsoft.com/download/pr/98ff0a08-a283-428f-8e54-19841d97154c/8c7d5f9600eadf264f04c82c813b7aab/dotnet-hosting-8.0.2-win.exe"
`}</code></pre></div>
<p>The URL from which the .NET 8 Hosting Bundle installer can be downloaded is specified. This URL is typically obtained from the official Microsoft download page.</p>

<h3>2.6.3. Installer Path Definition:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installerPath = "C:\\dotnet-hosting-8.0.2-win.exe"
`}</code></pre></div>
<p>The script sets the path on the VM where the Hosting Bundle installer will be saved.</p>

<h3>2.6.4. Download and Installation Commands:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installDotNetHostingBundle = "@
Invoke-WebRequest -Uri $dotnetHostingBundleInstallerUrl -OutFile $installerPath
Start-Process -FilePath $installerPath -ArgumentList '/install', '/quiet', '/norestart' -Wait
Remove-Item -Path $installerPath -Force
"@`}</code></pre></div>
<p>This block of PowerShell script is responsible for downloading the Hosting Bundle installer to the VM, executing it silently, and cleaning up by removing the installer file after completion.</p>

<h3>2.6.5. Execution on the VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installDotNetHostingBundle
`}</code></pre></div>
<p>Similar to previous steps, the script runs the Hosting Bundle installation commands on the remote VM.</p>

<h3>2.6.6. Confirming Successful Installation:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output ".NET 8 Hosting Bundle Installation Successful"
`}</code></pre></div>
<p>If the installation commands execute without errors, a success message is displayed.</p>

<h3>2.6.7. Error Handling:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`catch
{
    Write-Output "Error Installing .NET 8 Hosting Bundle: $_"
}
`}</code></pre></div>
<p>A `try-catch` block encapsulates the installation process to catch any errors. If an error occurs, an error message is outputted.</p>

<p>Completing the installation of the .NET 8 Hosting Bundle is a key step in preparing the Azure VM to host .NET web applications. This automation exemplifies the power of infrastructure as code, leading to more efficient, reproducible, and reliable deployments.</p>

<p>With everything now setup, we can execute the PowerShell script. Open a terminal prompt, navigate to the directory where the PowerShell script is located, and execute it. After a few moments, you will be required to enter the password for the admin user on the machine we earlier configured. Upon successful completion, the public IP address of the VM will be outputted in the terminal. This will be needed for our GitHub actions workflows CI CD yaml file</p>

<h2>3. Create .NET Web API Project</h2>

<p>In this step, we will create a boilerplate .NET 8 web API project. To do this, open a command prompt or PowerShell window, and enter the following:</p>

<div class="code-block"><pre><code class="language-c-sharp">{`dotnet new webapi -n AzWebAPI
`}</code></pre></div>

<h2>4. Create GitHub Repository</h2>

<p>After creating the boilerplate web API project, create a new GitHub repository for the web API either via command line or via Visual Studio’s GUI interface. After doing so, we need to set some repository variables that will be used throughout the workflows CI/CD pipeline file.</p>

<h3>4.1 VM Public IP Address Variable</h3>
<p></p>
<p>In GitHub, navigate to the repository settings & under the "Secrets and variables" section in the secutiry settings, add the IP address in the actions section</p>
<img src="https://kmc-technologies.ltd/Blogs/CI-CD-Blog/IPConf.png"></img>
<p></p>
<h3>4.2 Private SSH Key Variable</h3>
<img src="https://kmc-technologies.ltd/Blogs/CI-CD-Blog/SSH-Conf.png"></img>
<p></p>
<p>Now do the same for your prviate SSH key, this can be found in ./ssh/id_rsa</p>
<p></p>
<h2>5. GitHub Actions CI/CD Workflow File</h2>

<p>In this section of the blog, we delve into the GitHub Actions workflow file that automates the build and deployment phases for a .NET Web API project. Automation of running unit tests can also be facilitated within this YAML file. Once any changes are committed to the .NET web API repository on the master branch, a series of sequenced operations are triggered that run on the latest Windows environments.</p>

<p>From restoring dependencies and compiling code, to optionally running unit tests and publishing artifacts, and finally deploying the application to a previously provisioned Azure VM— the workflow codifies the steps required to ensure that your .NET application is production-ready at every commit. Let's explore the nuances of this workflow file and how it integrates with the Azure VM setup we previously established through PowerShell scripting, creating a seamless pipeline from code commit to live deployment.</p>

<h2>5.1 CI/CD Pipeline</h2>
<div class="code-block"><pre><code class="language-yaml">{`name: CI/CD Pipeline

on:
  push:
    branches:
      - master
`}</code></pre></div>
<p>This setup defines the initiation of our CI/CD process, ready to act on updates to the master branch, ensuring continuous integration with each code push.</p>

<h3>5.2 Build & Deploy Config</h3>
<p>We then define a <code>build-and-deploy</code> job set to run on the latest Windows environment. This job is the orchestrator, guiding the steps required to transform the code into a deployable state.</p>
<div class="code-block"><pre><code class="language-yaml">{`jobs:
  build-and-deploy:
    runs-on: windows-latest
`}</code></pre></div>
<p>The process kicks off with the <code>checkout</code> action, fetching the repository's code into the GitHub Actions runner, making the latest commits ready for the subsequent steps.</p>
<div class="code-block"><pre><code class="language-yaml">{`steps:
- uses: actions/checkout@v2
`}</code></pre></div>

<h3>5.3 Setup .NET & Build Project</h3>
<p>Following this, the <code>setup-dotnet</code> action prepares the environment with the .NET version (<code>8.0.x</code>), ensuring the runner has the necessary runtime and SDK for building the .NET application.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Setup .NET
  uses: actions/setup-dotnet@v2
  with:
    dotnet-version: '8.0.x'
`}</code></pre></div>
<p>The <code>dotnet restore</code> command comes next, restoring the project's dependencies to ensure all necessary packages are available for the build process.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Restore dependencies
  run: dotnet restore
`}</code></pre></div>
<p>With dependencies in place, <code>dotnet build</code> compiles the code into executable artifacts. The <code>--no-restore</code> flag signifies that dependency restoration is already handled, focusing the process solely on compilation.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Build Application
  run: dotnet build --no-restore
`}</code></pre></div>
<p>To verify the application's integrity, <code>dotnet test</code> runs the test suite, ensuring the code performs as intended.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Run Tests
  run: dotnet test --no-build
`}</code></pre></div>
<p>As the build and test phases conclude, <code>dotnet publish</code> packages the application into a deployable format, readying it for deployment.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Publish Application
  run: dotnet publish AzWebAPI/AzWebAPI.csproj --configuration Release --output publish/AzWebAPI
`}</code></pre></div>

<h2>5.4 File Transfer & Deployment to VM</h2>

<p>Before deployment, an SSH Agent is set up, utilizing the securely stored SSH private key from GitHub Secrets, establishing a secure connection to the Azure VM.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Create SSH Agent
  uses: webfactory/ssh-agent@v0.5.3
  with:
    ssh-private-key: $secrets.SSH_PRIVATE_KEY
`}</code></pre></div>

<p>A simple command is executed to confirm the SSH connection's readiness for deployment, marking the preparation for the final deployment steps.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Test SSH Connection
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.VM_IP_ADDRESS "echo Connection successful"
  shell: bash
`}</code></pre></div>

<p>Continuing the narrative of our CI/CD journey, we delve into the deployment phase, where the prepared artifacts are seamlessly transitioned to the live environment. This phase is marked by critical steps that ensure the application is not only deployed but also backed up and verified, embodying a holistic approach to continuous delivery.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Backup Current wwwroot
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY 'powershell -Command "& {$timestamp = Get-Date -Format ''yyyyMMddHHmmss''; $destination = \"C:/inetpub/wwwroot_backup_$timestamp\"; if (!(Test-Path $destination)) {New-Item -ItemType Directory -Path $destination}; Copy-Item -Path C:/inetpub/wwwroot/* -Destination $destination -Recurse -Force}"'
  shell: bash
`}</code></pre></div>

<p>This step ensures that the current state of the `wwwroot` directory is preserved by creating a timestamped backup. This safeguard allows for recovery in unforeseen circumstances, providing a snapshot of the application before the new deployment.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Delete Current wwwroot Content and Ensure Directory Exists
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Get-ChildItem -Path C:/inetpub/wwwroot -Exclude wwwroot_backup_* | Remove-Item -Recurse -Force; if (-not (Test-Path -Path C:/inetpub/wwwroot)) { New-Item -Path C:/inetpub/wwwroot -ItemType Directory }\""
  shell: bash
`}</code></pre></div>

<p>Following the backup, the `wwwroot` directory is cleansed of its contents, ensuring a pristine environment for the new deployment. This step meticulously avoids disrupting the backup directories, maintaining the integrity of the backups.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: List Files in Publish Directory
  run: ls -l publish/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>Before proceeding with the file transfer, a listing of the files in the publish directory provides visibility into the artifacts ready for deployment, offering a checkpoint for verification.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Transfer Published Files to VM
  run: |
    scp -o StrictHostKeyChecking=no -r ./publish/AzWebAPI azureuser@$secrets.SSH_PRIVATE_KEY:C:/inetpub/wwwroot/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>The prepared artifacts are then securely transferred to the Azure VM, placing them within the `wwwroot` directory. This step utilizes secure copy protocol (SCP) to ensure the integrity and security of the file transfer.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Copy New WebAPI to wwwroot
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"if (Test-Path -Path C:/inetpub/wwwroot/AzWebAPI) { Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | ForEach-Object { Copy-Item -Path $_.FullName -Destination (Join-Path C:/inetpub/wwwroot $_.Name) -Force } } else { Write-Output 'C:/inetpub/wwwroot/AzWebAPI does not exist, skipping copy.' }\""
  shell: bash
`}</code></pre></div>

<p>Upon successful transfer, the contents of the `AzWebAPI` directory are meticulously integrated into the `wwwroot` directory, ensuring that the new version of the application is ready for access.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Move AzWebAPI contents to wwwroot
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | Move-Item -Destination C:/inetpub/wwwroot\""
  shell: bash
`}</code></pre></div>

<p>This step further ensures that the contents of the `AzWebAPI` are not just copied but also moved to the root of the `wwwroot` directory, aligning with the desired structure for the live application.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Restart IIS
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Restart-Service -Name W3SVC -Force\""
  shell: bash
`}</code></pre></div>

<p>The culmination of the deployment phase is marked by restarting the Internet Information Services (IIS), ensuring that all updates are loaded and the application is served with its newest features. This final step breathes life into the deployed application, making it accessible to users in its most updated form.</p>

<p>Through these meticulously crafted steps, the GitHub Actions workflow encapsulates the essence of continuous delivery, ensuring that every aspect of the deployment is handled with care, from backing up the current state to seamlessly integrating the new version into the live environment. This approach not only streamlines the deployment process but also embeds safeguards and verifications, ensuring the highest level of reliability and integrity in delivering updates to the live application.</p>

<p>In a web browser, if you now navigate to your Azure’s Virtual Machine public IP address followed by the default .NET web API weather forecast endpoint (/WeatherForecast), you will see the boilerplate JSON response.</p>

<p>And with that, we draw the curtains on this installment of our journey through the automation landscape. But rest assured, the adventure doesn't end here. In an upcoming post, I'll be diving into the realms of either Azure Virtual Machines or Docker containers to craft a bespoke iteration of GitHub Actions tailored to our unique needs. So, keep your eyes on this space and stay tuned for more insights and explorations in the ever-evolving world of DevOps. Your continued engagement fuels our exploration into these technological frontiers. Until next time, happy coding!</p>

<p>Following the backup, the `wwwroot` directory is cleansed of its contents, ensuring a pristine environment for the new deployment. This step meticulously avoids disrupting the backup directories, maintaining the integrity of the backups.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: List Files in Publish Directory
  run: ls -l publish/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>Before proceeding with the file transfer, a listing of the files in the publish directory provides visibility into the artifacts ready for deployment, offering a checkpoint for verification.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Transfer Published Files to VM
  run: |
    scp -o StrictHostKeyChecking=no -r ./publish/AzWebAPI azureuser@$secrets.SSH_PRIVATE_KEY:C:/inetpub/wwwroot/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>The prepared artifacts are then securely transferred to the Azure VM, placing them within the `wwwroot` directory. This step utilizes secure copy protocol (SCP) to ensure the integrity and security of the file transfer.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Copy New WebAPI to wwwroot
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"if (Test-Path -Path C:/inetpub/wwwroot/AzWebAPI) { Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | ForEach-Object { Copy-Item -Path $_.FullName -Destination (Join-Path C:/inetpub/wwwroot $_.Name) -Force } } else { Write-Output 'C:/inetpub/wwwroot/AzWebAPI does not exist, skipping copy.' }\""
  shell: bash
`}</code></pre></div>

<p>Upon successful transfer, the contents of the `AzWebAPI` directory are meticulously integrated into the `wwwroot` directory, ensuring that the new version of the application is ready for access.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Move AzWebAPI contents to wwwroot
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | Move-Item -Destination C:/inetpub/wwwroot\""
  shell: bash
`}</code></pre></div>

<p>This step further ensures that the contents of the `AzWebAPI` are not just copied but also moved to the root of the `wwwroot` directory, aligning with the desired structure for the live application.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Restart IIS
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Restart-Service -Name W3SVC -Force\""
  shell: bash
`}</code></pre></div>

<p>The culmination of the deployment phase is marked by restarting the Internet Information Services (IIS), ensuring that all updates are loaded and the application is served with its newest features. This final step breathes life into the deployed application, making it accessible to users in its most updated form.</p>

<p>Through these meticulously crafted steps, the GitHub Actions workflow encapsulates the essence of continuous delivery, ensuring that every aspect of the deployment is handled with care, from backing up the current state to seamlessly integrating the new version into the live environment. This approach not only streamlines the deployment process but also embeds safeguards and verifications, ensuring the highest level of reliability and integrity in delivering updates to the live application.</p>

<p>In a web browser, if you now navigate to your Azure’s Virtual Machine public IP address followed by the default .NET web API weather forecast endpoint (/WeatherForecast), you will see the boilerplate JSON response.</p>

<p>And with that, we draw the curtains on this installment of our journey through the automation landscape. But rest assured, the adventure doesn't end here. In an upcoming post, I'll be diving into the realms of either Azure Virtual Machines or Docker containers to craft a bespoke iteration of GitHub Actions tailored to our unique needs. So, keep your eyes on this space and stay tuned for more insights and explorations in the ever-evolving world of DevOps. Your continued engagement fuels our exploration into these technological frontiers. Until next time, happy coding!</p>

































    </div>),
   
    contentDe:(
    <div class="content-item">
    <p></p>
    <p>Grüße, in diesem Beitrag werden wir den Prozess der Einrichtung einer CI/CD-Pipeline über GitHub-Workflow-Aktionen für eine .NET-Web-API-Anwendung, die auf einer Azure-Virtual-Machine über IIS remote ausgeführt wird, erörtern. Einige Gründe, warum Sie eine Web-API auf einer VM und nicht über die Standard-Web-API-Option von Azure hosten möchten, umfassen die vollständige Kontrolle über die Umgebung, erweiterte Netzwerkfunktionen und verbesserte Sicherheitskontrollen.</p>
    <p></p>
    <p>Dieser Beitrag wird Folgendes umreißen;</p><p></p>
    <ol><li>Erstellen eines SSH-Schlüsselpaares zur Verbindung mit der Azure-Virtual-Machine.</li>
    <li>Erstellen einer Azure-VM</li>
    <li>Erstellen einer GitHub-Workflow-Datei zum Bereitstellen eines .NET-Web-API-Projekts auf der Virtual Machine, die IIS ausführt</li></ol><p>
    </p><p>Die Bereitstellung der virtuellen Maschine und ihrer notwendigen Abhängigkeiten wird über ein Windows-PowerShell-Skript ausgeführt, um die Automatisierung zu erleichtern.</p>
    <p>Lassen Sie uns beginnen!</p>

    <p><strong>Wenn Sie diesem Tutorial folgen möchten, müssen Sie die Microsoft Azure CLI installieren und sich mit dem Befehl az login bei Ihrem Azure-Konto anmelden:</strong></p>
<p><div class="code-block"><pre><code class="language-c-sharp">{`az login
`}</code></pre></div></p>
<h2>1. Erstellen eines SSH-Schlüsselpaares</h2>
<p></p>
<p>Bevor wir beginnen, werden wir ein SSH-Schlüsselpaar auf einem lokalen Rechner generieren. Dieses Schlüsselpaar wird verwendet, um Dateien während eines CI/CD-Prozesses sicher zu übertragen, wenn Änderungen am .NET-Web-API-Code vorgenommen werden. Um dieses Schlüsselpaar zu generieren, verwenden Sie den folgenden Befehl:</p>
<p></p>
<div class="code-block"><pre><code class="language-c-sharp">{`ssh-keygen -t rsa -b 2048
`}</code></pre></div>
<p></p>
<p>Nach Eingabe dieses Befehls werden Sie aufgefordert, das Schlüsselpaar in einer Datei zu speichern. Für den Moment kann dies leer gelassen werden, indem Sie einfach die Eingabetaste drücken. Dadurch wird das Schlüsselpaar im Standardverzeichnis .ssh gespeichert. Darüber hinaus werden Sie aufgefordert, ein Passwort einzugeben, dies kann ebenfalls leer gelassen werden.</p>
<p></p>
<p>Nachdem das Schlüsselpaar generiert wurde, können wir nun ein Windows-Powershell-Skript erstellen, das eine virtuelle Maschine innerhalb von Microsoft Azure bereitstellt. Lassen Sie uns mit dem Schreiben des Skripts beginnen.</p>

<h2><strong>2. Automatisierte Bereitstellung virtueller Maschinen mit PowerShell</strong></h2>
<p></p>
<p>Erstellen Sie ein neues Windows-Powershell-Skript mit der Dateiendung .ps1 und fügen Sie jedes der folgenden Code-Snippets zum Skript hinzu:</p>
<p></p>
<h3><strong>2.1.1. Eine neue Ressourcengruppe erstellen:</strong></h3>
<p></p>
<div class="code-block"><pre><code class="language-c-sharp">{`New-AzResourceGroup -Name 'RgAzVMWebAPI' -Location 'ukwest'
`}</code></pre></div>
<p></p>
<p>Die obige Zeile erstellt eine neue Azure-Ressourcengruppe mit dem Namen 'RgAzVMWebAPI' in der Azure-Region 'UK West'. Ressourcengruppen in Azure sind Container, die verwandte Ressourcen für eine Azure-Lösung enthalten – in diesem Fall die VM und zugehörige Ressourcen.</p>

<h3><strong>2.1.2 Sichere Passworterstellung:</strong></h3>
<p></p>
<div class="code-block"><pre><code class="language-c-sharp">{`powershell

  $securePassword = ConvertTo-SecureString "Password!234" -AsPlainText -Force
`}</code></pre></div>
<p></p>
<p>Hier konvertieren wir ein Klartext-Passwort in eine sichere Zeichenfolge. Diese sichere Zeichenfolge wird verschlüsselt, was sie sicherer für die Verwendung in Skripten und Prozessen macht. Der Parameter `-Force` wird verwendet, um die Bestätigungsaufforderung zu umgehen, die typischerweise bei dieser Art von Operation erscheinen würde. Offensichtlich sollte in einer Produktionsumgebung ein wesentlich stärkeres Passwort verwendet werden!</p>

<h3><strong>2.1.3. Erstellung eines Anmeldeinformationsobjekts:</strong></h3>
<p></p>
<div class="code-block"><pre><code class="language-c-sharp">{`powershell

  $credential = New-Object System.Management.Automation.PSCredential ("azureuser", $securePassword)
`}</code></pre></div>
<p></p>
<p>Mit dem sicheren Passwort wird ein `PSCredential`-Objekt mit dem Benutzernamen 'azureuser' erstellt. Dieses Anmeldeinformationsobjekt wird verwendet, um sicher mit Azure-Diensten zu authentifizieren.</p>

<h3><strong>2.1.4. Bereitstellung der virtuellen Maschine:</strong></h3>
<p></p>
<div class="code-block"><pre><code class="language-c-sharp">{`$result1 = New-AzVm 
 -ResourceGroupName 'RgAzVMWebAPI' 
 -Name 'AzVMWebAPI' 
 -Location 'ukwest' 
 -Image 'MicrosoftWindowsServer:WindowsServer:2022-datacenter-azure-edition:latest' 
 -Size 'Standard_B2s' 
 -VirtualNetworkName 'AzVMWebAPIVNet' 
 -SubnetName 'AzVMWebAPISubnet' 
 -SecurityGroupName 'AzVMWebAPINSG' 
 -PublicIpAddressName 'AzVMWebAPIPublicIP' 
 -OpenPorts 80,3389,22 

`}</code></pre></div>
<p></p>

<p>Dieser mehrzeilige Befehl (`New-AzVm`) leitet die Erstellung einer neuen VM ein. Er gibt verschiedene Parameter an, wie den Namen der Ressourcengruppe, den Namen der VM, den Standort und das zu verwendende Image (Windows Server 2022 Datacenter Azure Edition). Er definiert auch die Größe der VM (`Standard_B2s`), Netzwerkinformationen (wie VNet- und Subnetz-Namen) und Sicherheitseinstellungen, einschließlich einer Netzwerksicherheitsgruppe und einer öffentlichen IP-Adresse. Der Parameter `-OpenPorts` wird verwendet, um sicherzustellen, dass die VM Verkehr auf bestimmten Ports empfangen kann: 80 (HTTP), 3389 (RDP für Fernzugriff auf den Desktop) und 22 (SSH).</p>
<p></p>
<p>Das Backtick (`) am Ende jeder Zeile ist das Zeichen für die Zeilenfortsetzung in PowerShell, was es dem Befehl erlaubt, über mehrere Zeilen für bessere Lesbarkeit zu verlaufen.</p>
<p></p>
<p>Das Ergebnis des VM-Erstellungsbefehls wird in der Variablen `$result1` gespeichert, die für weitere Verarbeitung oder Validierungsüberprüfungen in nachfolgenden Skriptabschnitten verwendet werden kann.</p>
<p></p>
<p>Der nächste Teil des Skripts besteht aus verschiedenen Befehlen, die SSH auf der VM installieren, zusammen mit der Angabe des Dateispeicherorts des öffentlichen Schlüssels des SSH-Schlüssels, den wir zuvor auf unserem lokalen Rechner generiert haben. Dieser öffentliche Schlüssel wird später verwendet, wenn bekannte SSH-Hosts auf unserer entfernten VM verifiziert werden. Bitte beachten Sie, dass es in einer Produktionsumgebung besser ist, Port 443 anstelle von Port 80 für eine sichere Verbindung zu verwenden. Port 80 wird in diesem Artikel zu Demonstrationszwecken verwendet.</p>

<h2><strong>2.2 Installation von Open SSH</strong></h2>

<p>Nachdem die VM bereitgestellt wurde, ist der nächste Schritt, den Zugriff über Secure Shell (SSH) zu konfigurieren. SSH ist ein Protokoll, das über ein unsicheres Netzwerk in einer Client-Server-Architektur einen sicheren Kanal bietet und so sicheres Einloggen von einem Computer zum anderen ermöglicht.</p>

<h3>2.2.1. Abruf des öffentlichen Schlüssels:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicKeyPath = 'C:\\Users\\kieroncairns\\.ssh\\id_rsa.pub'
$publicKey = Get-Content $publicKeyPath -Raw | Out-String | ForEach-Object { $_.TrimEnd() }
`}</code></pre></div>
<p>Das Skript definiert zunächst den Pfad zur Datei des öffentlichen SSH-Schlüssels. Anschließend liest es den Inhalt dieser Datei und speichert ihn in der Variable `$publicKey`. Dieser öffentliche Schlüssel wird für die SSH-Authentifizierung verwendet und ermöglicht einen sicheren passwortlosen Zugang zur VM.</p>

<h3>2.2.2. Installationsskript für OpenSSH:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installSsh = "@
    Add-WindowsCapability -Online -Name OpenSSH.Server
    Start-Service sshd
    Set-Service -Name sshd -StartupType 'Automatic'
"@`}</code></pre></div>
<p>Hier wird eine mehrzeilige Zeichenkette (ein sog. Here-String in der PowerShell-Terminologie) der Variablen `$installSsh` zugewiesen. Diese Zeichenkette enthält Befehle, um die Funktion des OpenSSH-Servers auf der Windows-VM zu installieren, den SSH-Dienst (`sshd`) zu starten und ihn so einzustellen, dass er automatisch mit dem Systemstart beginnt.</p>

<h3>2.2.3. Ausführen des Installationsskripts auf der VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`try {
    Write-Output "Installation von Open SSH auf VM"
    Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installSsh
    Write-Output "Installation von Open SSH erfolgreich"
}
catch {
    Write-Output "Fehler bei der Installation von Open SSH"
}`}</code></pre></div>
<p>Das Skript versucht dann, die Installationsbefehle für OpenSSH auf der VM mit dem Cmdlet `Invoke-AzVMRunCommand` auszuführen. Dieses Cmdlet ermöglicht es Ihnen, PowerShell-Skripte direkt auf Azure-VMs auszuführen. Wenn die Installation erfolgreich ist, wird eine Erfolgsmeldung ausgegeben. Tritt während dieses Prozesses ein Fehler auf, fängt der Catch-Block den Fehler ab und gibt eine Fehlermeldung aus.</p>
<p>Die Verwendung von `try-catch`-Blöcken in PowerShell ist eine effektive Methode, um Ausnahmen und Fehler in Skripten zu behandeln. Sie stellt sicher, dass das Skript eventuell auftretende Probleme während der Ausführung elegant handhaben kann und gibt eine klare Rückmeldung über den Erfolg oder Misserfolg der Operation.</p>

<h2><strong>2.2 Installation von Open SSH</strong></h2>

<p>Nachdem die VM bereitgestellt wurde, besteht der nächste Schritt darin, den Zugriff über Secure Shell (SSH) zu konfigurieren. SSH ist ein Protokoll, das einen sicheren Kanal über ein unsicheres Netzwerk in einer Client-Server-Architektur bietet und so einen sicheren Login von einem Computer zum anderen ermöglicht.</p>

<h3>2.2.1. Abruf des öffentlichen Schlüssels:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicKeyPath = 'C:\\Users\\kieroncairns\\.ssh\\id_rsa.pub'
$publicKey = Get-Content $publicKeyPath -Raw | Out-String | ForEach-Object { $_.TrimEnd() }
`}</code></pre></div>
<p>Das Skript definiert zunächst den Pfad zur Datei des öffentlichen SSH-Schlüssels. Dann liest es den Inhalt dieser Datei und speichert ihn in der Variablen `$publicKey`. Dieser öffentliche Schlüssel wird für die SSH-Authentifizierung verwendet und ermöglicht einen sicheren passwortlosen Zugang zur VM.</p>

<h3>2.2.2. Installationsskript für OpenSSH:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installSsh = "@
    Add-WindowsCapability -Online -Name OpenSSH.Server
    Start-Service sshd
    Set-Service -Name sshd -StartupType 'Automatic'
"@`}</code></pre></div>
<p>Hier wird eine mehrzeilige Zeichenkette (ein sog. Here-String in der PowerShell-Terminologie) der Variablen `$installSsh` zugewiesen. Diese Zeichenkette enthält Befehle, um die Funktion des OpenSSH-Servers auf der Windows-VM zu installieren, den SSH-Dienst (`sshd`) zu starten und ihn so einzustellen, dass er automatisch mit dem Systemstart beginnt.</p>

<h3>2.2.3. Ausführen des Installationsskripts auf der VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`try {
    Write-Output "Installation von Open SSH auf VM"
    Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installSsh
    Write-Output "Installation von Open SSH erfolgreich"
}
catch {
    Write-Output "Fehler bei der Installation von Open SSH"
}`}</code></pre></div>
<p>Das Skript versucht dann, die Installationsbefehle für OpenSSH auf der VM mit dem Cmdlet `Invoke-AzVMRunCommand` auszuführen. Dieses Cmdlet ermöglicht es Ihnen, PowerShell-Skripte direkt auf Azure-VMs auszuführen. Wenn die Installation erfolgreich ist, wird eine Erfolgsmeldung ausgegeben. Tritt während dieses Prozesses ein Fehler auf, fängt der Catch-Block den Fehler ab und gibt eine Fehlermeldung aus.</p>
<p>Die Verwendung von `try-catch`-Blöcken in PowerShell ist eine effektive Methode, um Ausnahmen und Fehler in Skripten zu behandeln. Sie stellt sicher, dass das Skript eventuell auftretende Probleme während der Ausführung elegant handhaben kann und gibt eine klare Rückmeldung über den Erfolg oder Misserfolg der Operation.</p>


<h2>2.3 Anmelden an der VM, um das notwendige Admin-Benutzerkonto-Verzeichnis zu erstellen</h2>

<p>Der nächste Teil des Skripts wird den Admin-Benutzer der VM per SSH anmelden, um das Benutzerkonto bei der ersten Verwendung der VM zu erstellen. Darüber hinaus wird der früher generierte öffentliche Schlüssel einer authorized_keys SSH-Datei hinzugefügt, die im Benutzerverzeichnis der Admin-Konten vorhanden ist. Dieser Schritt ermöglicht eine schlüsselbasierte Authentifizierung über SSH, die im nächsten Segment des Skripts ausgeführt wird:</p>

<h3>2.3.1. Öffentliche IP-Adresse abrufen:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$publicIp = Get-AzPublicIpAddress -ResourceGroupName 'RgAzVMWebAPI' -Name 'AzVMWebAPIPublicIP'
$ipAddress = $publicIp.IpAddress
`}</code></pre></div>
<p>Das Skript verwendet das Cmdlet `Get-AzPublicIpAddress`, um die der VM zugewiesene öffentliche IP-Adresse abzurufen. Diese IP-Adresse ist entscheidend für die Herstellung von Fernverbindungen.</p>

<h3>2.3.2. Verfügbarkeit der IP-Adresse prüfen:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`if (-not $ipAddress) {
    throw "IP-Adresse konnte nicht abgerufen werden."
}
`}</code></pre></div>
<p>Es wird überprüft, ob die IP-Adresse erfolgreich abgerufen wurde. Wenn nicht, wird eine Ausnahme geworfen, was das Skript effektiv stoppt und auf ein Problem im Prozess hinweist.</p>

<h3>2.3.3. IP-Adresse anzeigen:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "VM-öffentliche IP-Adresse: $ipAddress"
`}</code></pre></div>
<p>Wenn die IP-Adresse erfolgreich abgerufen wird, wird sie dem Benutzer angezeigt.</p>

<h3>2.3.4. SSH für Remote-Login konfigurieren:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$sshCommands = "@
echo 'Erstellen des Benutzerprofils auf der VM und Hinzufügen des öffentlichen Schlüssels zur Datei authorized_keys...'
mkdir C:\\Users\\azureuser\\.ssh
echo $publicKey > C:\\Users\\azureuser\\.ssh\\authorized_keys
exit
"@`}</code></pre></div>
<p>Das Skript bereitet eine Reihe von SSH-Befehlen vor, um ein `.ssh`-Verzeichnis im Home-Verzeichnis des Azure-Benutzers zu erstellen und den zuvor abgerufenen öffentlichen Schlüssel zur Datei `authorized_keys` hinzuzufügen. Diese Einrichtung ermöglicht eine sichere schlüsselbasierte Authentifizierung.</p>

<h3>2.3.5. SSH-Verbindung herstellen und Befehle ausführen:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$sshCommand = "ssh -o StrictHostKeyChecking=no azureuser@$ipAddress"
$fullCommand = "echo \`"$sshCommands\`" | $sshCommand"
Invoke-Expression $fullCommand
`}</code></pre></div>
<p>Das Skript konstruiert dann einen SSH-Befehl, um eine Verbindung zur VM herzustellen, und leitet die beim Login auszuführenden SSH-Befehle weiter. Die Verwendung von `StrictHostKeyChecking=no` umgeht die Überprüfung des Host-Schlüssels; dies wird für Produktionsumgebungen nicht empfohlen, da es die Verbindung anfällig für Man-in-the-Middle-Angriffe machen könnte.</p>

<h3>2.3.6. Fehlerbehandlung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`} catch {
    Write-Error "Ein Fehler ist aufgetreten: $_"
}
`}</code></pre></div>
<p>Das Skript ist in einen try-catch-Block eingefasst, um mögliche Fehler während der SSH-Einrichtung zu behandeln. Tritt ein Fehler auf, wird dieser erfasst und ausgegeben, und das Skript kann alle notwendigen Aufräumarbeiten durchführen.</p>

<h2>2.4 Deaktivierung der Passwortauthentifizierung & Aktivierung der schlüsselbasierten Authentifizierung über SSH</h2>

<p>Dieser Teil des Skripts ist wesentlich, um sicherzustellen, dass die VM sicher über SSH verwaltet werden kann, ohne dass eine Passwortauthentifizierung erforderlich ist, was ein wichtiger Aspekt der Automatisierung der VM-Verwaltung ist. Das Skript automatisiert nicht nur die Bereitstellung der VM, sondern auch ihre anfängliche Konfiguration, wodurch die manuellen Schritte, die erforderlich sind, um eine VM in Betrieb zu nehmen, reduziert werden, was ein Schlüsselaspekt in der GitHub Actions Workflow CI CD YAML-Datei sein wird.</p>

<h3>2.4.1. Definition des Pfads der SSH-Konfigurationsdatei:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$filePath = "C:\\ProgramData\\ssh\\sshd_config"
`}</code></pre></div>
<p>Das Skript legt den Pfad zur SSH-Serverkonfigurationsdatei `sshd_config` fest, die Einstellungen enthält, die das Verhalten des SSH-Servers bestimmen.</p>

<h3>2.4.2. Deaktivierung der Passwortauthentifizierung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disablePwdAuthentication = "@
(Get-Content $filePath) -replace '#PasswordAuthentication yes', 'PasswordAuthentication no' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>Dieser Codeblock dient dazu, die Sicherheit zu erhöhen, indem die Passwortauthentifizierung für SSH deaktiviert wird, sodass alle Benutzer stattdessen über SSH-Schlüssel eine Verbindung herstellen müssen. Dies geschieht durch Ändern der SSH-Konfigurationsdatei, um `PasswordAuthentication` auf `no` zu setzen, und anschließendes Neustarten des SSH-Dienstes, um die Änderungen anzuwenden.</p>

<h3>2.4.3. Aktivierung der öffentlichen Schlüsselauthentifizierung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$enablePubKeyAuthentication = "@
(Get-Content $filePath) -replace '#PubkeyAuthentication yes', 'PubkeyAuthentication yes' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>Dieser Code stellt sicher, dass die Authentifizierung mit öffentlichen Schlüsseln aktiviert ist, sodass Benutzer sich mit ihren SSH-Schlüsseln authentifizieren können. Er ändert die entsprechende Einstellung in der Konfigurationsdatei und startet den SSH-Dienst neu.</p>

<h3>2.4.4. Deaktivierung der Match Group Admins:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disableMatchGroupAdmins = "@
(Get-Content $filePath) -replace 'Match Group administrators', '#Match Group administrators' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>In diesem Schritt kommentiert das Skript alle spezifischen Einstellungen aus, die nur für Benutzer in der `administrators`-Gruppe gelten. Dies ist eine Möglichkeit, sicherzustellen, dass die SSH-Konfigurationen einheitlich für alle Benutzer gelten.</p>

<h3>2.4.5. Deaktivierung der Admin Authorized Keys-Datei:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$disableAdminAuthorizedKeysFile = "@
(Get-Content $filePath) -replace 'AuthorizedKeysFile __PROGRAMDATA__/ssh/administrators_authorized_keys', '#AuthorizedKeysFile __PROGRAMDATA__/ssh/administrators_authorized_keys' | Set-Content $filePath
Restart-Service sshd
"@`}</code></pre></div>
<p>Dieser Abschnitt des Skripts kommentiert die Zeile aus, die eine separate `authorized_keys`-Datei für Administratorbenutzer angibt, um sicherzustellen, dass dieselbe `authorized_keys`-Datei für alle Benutzer verwendet wird.</p>

<h3>2.4.6. Durchführung von Konfigurationsänderungen:</h3>
<p>Jeder Block von Konfigurationsänderungen wird auf der VM mit dem Cmdlet `Invoke-AzVMRunCommand` ausgeführt. Dieses Cmdlet ermöglicht es dem Skript, PowerShell-Befehle auf der Remote-VM auszuführen. Nachdem jeder Befehlsblock ausgeführt wurde, gibt es eine Ausgabe an den Benutzer, die die durchgeführte Aktion angibt (z. B. "Passwortauthentifizierung in sshd_config deaktiviert").</p>

<h3>2.4.7. Fehlerbehandlung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`} catch {
    Write-Output "Fehler beim Erstellen des SSH-Verzeichnisses: $_"
}
`}</code></pre></div>
<p>Der `try-catch`-Block kapselt die Befehle ein, um alle Fehler zu behandeln, die während der Ausführung der Konfigurationsänderungen auftreten. Tritt ein Fehler auf, wird er erfasst und eine Nachricht wird dem Benutzer angezeigt.</p>

<p>Dieser Abschnitt ist entscheidend für die Sicherung des SSH-Servers durch Implementierung der schlüsselbasierten Authentifizierung und Deaktivierung der weniger sicheren Passwortauthentifizierung. Das Skript vereinfacht den Prozess der Absicherung der SSH-Konfiguration, was ein wichtiger Aspekt beim Bereitstellen einer sicheren und produktionsbereiten VM in der Cloud ist.</p>

<h2>2.5 Installation von .NET 8</h2>

<p>Mit der nun eingerichteten und gesicherten Azure-VM ist der nächste Schritt in unserem Bereitstellungsprozess die Installation der notwendigen Software für den Betrieb der .NET-Web-API. In diesem Fall konzentrieren wir uns auf die Installation der .NET 8-Laufzeitumgebung, die für den Betrieb des Web-API-Projekts unerlässlich ist.</p>

<h3>2.5.1. Beginn des Installationsprozesses:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Beginn der Installation der .NET 8-Laufzeitumgebung"
`}</code></pre></div>
<p>Diese Zeile gibt einfach eine Nachricht auf der Konsole aus, die darauf hinweist, dass die Installation der .NET 8-Laufzeitumgebung kurz bevorsteht.</p>

<h3>2.5.2. Angabe der URL des Installationsprogramms:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$dotnetRuntimeInstallerUrl = "https://download.visualstudio.microsoft.com/download/pr/ab5e947d-3bfc-4948-94a1-847576d949d4/bb11039b70476a33d2023df6f8201ae2/dotnet-sdk-8.0.201-win-x64.exe"
`}</code></pre></div>
<p>Hier definieren wir die URL, unter der das Installationsprogramm für die .NET 8-Laufzeitumgebung heruntergeladen werden kann. Diese URL verweist auf den offiziellen Microsoft-Server, der das Installationspaket hostet.</p>

<h3>2.5.3. Definition des lokalen Installationspfads:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installerPath = "C:\\dotnet-sdk-8.0.201-win-x64.exe"
`}</code></pre></div>
<p>Das Skript legt einen lokalen Pfad auf der VM fest, an den das Installationsprogramm für die .NET 8-Laufzeitumgebung heruntergeladen wird.</p>

<h3>2.5.4. Herunterladen und Installieren der .NET 8-Laufzeitumgebung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installDotNetRuntime = "@
Invoke-WebRequest -Uri $dotnetRuntimeInstallerUrl -OutFile $installerPath
Start-Process -FilePath $installerPath -ArgumentList '/quiet', '/norestart' -Wait
Remove-Item -Path $installerPath -Force
"@`}</code></pre></div>
<p>Dieser Codeblock ist ein mehrzeiliger PowerShell-Befehl, der drei Aufgaben übernimmt: das Herunterladen des Installationsprogramms, dessen Ausführung mit den Argumenten für einen stillen Betrieb (ohne Benutzerinteraktion) und ohne Neustart sowie das Aufräumen durch Löschen der Installationsdatei.</p>

<h3>2.5.5. Durchführung der Installation auf der VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installDotNetRuntime
`}</code></pre></div>
<p>Diese Zeile führt die Installationsbefehle auf der Remote-VM mit dem Cmdlet `Invoke-AzVMRunCommand` aus, das es uns ermöglicht, PowerShell-Skripte auf Azure-VMs auszuführen.</p>

<h3>2.5.6. Bestätigung des Erfolgs:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Installation der .NET 8-Laufzeitumgebung erfolgreich"
`}</code></pre></div>
<p>Nachdem die Installationsbefehle erfolgreich ausgeführt wurden, gibt das Skript eine Bestätigungsnachricht aus.</p>

<h3>2.5.7. Fehlerbehandlung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`catch
{
    Write-Output "Fehler bei der Installation der .NET 8-Laufzeitumgebung: $_"
}
`}</code></pre></div>
<p>Der `try-catch`-Block wird hier verwendet, um alle Ausnahmen zu behandeln, die während des Installationsprozesses auftreten können. Tritt ein Fehler auf, wird er erfasst und eine Fehlermeldung wird angezeigt.</p>

<p>Dieser Abschnitt stellt sicher, dass die Serverumgebung mit der notwendigen Laufzeitumgebung für die .NET-Web-API-Anwendung vorbereitet ist. Durch die Skriptierung dieser Installation automatisieren wir einen ansonsten manuellen und potenziell fehleranfälligen Prozess, was zu einer effizienteren und zuverlässigeren Einrichtung führt.</p>

<h2>2.6 Installation des .NET 8 Hosting-Bundles</h2>

<p>Die letzte Aufgabe des Skripts ist die Installation des .NET 8 Hosting-Bundles, das aus einer Reihe von Komponenten besteht, die zum Hosten von .NET-Anwendungen auf Windows-Servern, insbesondere in IIS (Internetinformationsdiensten), benötigt werden. Dies ist ein kritischer Schritt, wenn die VM als Webserver für .NET-Webanwendungen dienen soll.</p>

<h3>2.6.1. Einleitung der Installation des Hosting-Bundles:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Beginn der Installation des .NET 8 Hosting-Bundles"
`}</code></pre></div>
<p>Eine Nachricht wird angezeigt, um anzugeben, dass der Installationsprozess für das .NET 8 Hosting-Bundle beginnt.</p>

<h3>2.6.2. URL des Hosting-Bundle-Installationsprogramms:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$dotnetHostingBundleInstallerUrl = "https://download.visualstudio.microsoft.com/download/pr/98ff0a08-a283-428f-8e54-19841d97154c/8c7d5f9600eadf264f04c82c813b7aab/dotnet-hosting-8.0.2-win.exe"
`}</code></pre></div>
<p>Die URL, von der das Installationsprogramm für das .NET 8 Hosting-Bundle heruntergeladen werden kann, wird angegeben. Diese URL wird in der Regel von der offiziellen Microsoft-Downloadseite bezogen.</p>

<h3>2.6.3. Definition des Installationspfads:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installerPath = "C:\\dotnet-hosting-8.0.2-win.exe"
`}</code></pre></div>
<p>Das Skript legt den Pfad auf der VM fest, an dem das Installationsprogramm für das Hosting-Bundle gespeichert wird.</p>

<h3>2.6.4. Download- und Installationsbefehle:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`$installDotNetHostingBundle = "@
Invoke-WebRequest -Uri $dotnetHostingBundleInstallerUrl -OutFile $installerPath
Start-Process -FilePath $installerPath -ArgumentList '/install', '/quiet', '/norestart' -Wait
Remove-Item -Path $installerPath -Force
"@`}</code></pre></div>
<p>Dieser PowerShell-Codeblock ist für das Herunterladen des Hosting-Bundle-Installationsprogramms auf die VM, dessen stille Ausführung und das Aufräumen durch Entfernen der Installationsdatei nach Abschluss verantwortlich.</p>

<h3>2.6.5. Ausführung auf der VM:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Invoke-AzVMRunCommand -ResourceGroupName 'RgAzVMWebAPI' -VMName 'AzVMWebAPI' -CommandId 'RunPowerShellScript' -ScriptString $installDotNetHostingBundle
`}</code></pre></div>
<p>Ähnlich wie bei den vorherigen Schritten führt das Skript die Installationsbefehle für das Hosting-Bundle auf der Remote-VM aus.</p>

<h3>2.6.6. Bestätigung der erfolgreichen Installation:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`Write-Output "Installation des .NET 8 Hosting-Bundles erfolgreich"
`}</code></pre></div>
<p>Wenn die Installationsbefehle ohne Fehler ausgeführt werden, wird eine Erfolgsmeldung angezeigt.</p>

<h3>2.6.7. Fehlerbehandlung:</h3>
<div class="code-block"><pre><code class="language-c-sharp">{`catch
{
    Write-Output "Fehler bei der Installation des .NET 8 Hosting-Bundles: $_"
}
`}</code></pre></div>
<p>Ein `try-catch`-Block kapselt den Installationsprozess ein, um mögliche Fehler abzufangen. Tritt ein Fehler auf, wird eine Fehlermeldung ausgegeben.</p>

<p>Die Fertigstellung der Installation des .NET 8 Hosting-Bundles ist ein wichtiger Schritt, um die Azure-VM für das Hosting von .NET-Webanwendungen vorzubereiten. Diese Automatisierung veranschaulicht die Leistungsfähigkeit der Infrastruktur als Code und führt zu effizienteren, reproduzierbaren und zuverlässigeren Bereitstellungen.</p>

<p>Nachdem nun alles eingerichtet ist, können wir das PowerShell-Skript ausführen. Öffnen Sie eine Terminal-Eingabeaufforderung, navigieren Sie zum Verzeichnis, in dem sich das PowerShell-Skript befindet, und führen Sie es aus. Nach einigen Momenten werden Sie aufgefordert, das Passwort für den Admin-Benutzer auf dem zuvor konfigurierten Rechner einzugeben. Nach erfolgreicher Fertigstellung wird die öffentliche IP-Adresse der VM im Terminal ausgegeben. Diese wird für unsere GitHub-Actions-Workflows CI/CD-YAML-Datei benötigt.</p>

<h2>3. Erstellen eines .NET-Web-API-Projekts</h2>

<p>In diesem Schritt erstellen wir ein Boilerplate-.NET-8-Web-API-Projekt. Öffnen Sie dazu eine Eingabeaufforderung oder ein PowerShell-Fenster und geben Sie Folgendes ein:</p>

<div class="code-block"><pre><code class="language-c-sharp">{`dotnet new webapi -n AzWebAPI
`}</code></pre></div>

<h2>4. Erstellen eines GitHub-Repositorys</h2>

<p>Nachdem das Boilerplate-Web-API-Projekt erstellt wurde, erstellen Sie ein neues GitHub-Repository für das Web-API, entweder über die Befehlszeile oder über die GUI-Schnittstelle von Visual Studio. Danach müssen wir einige Repository-Variablen festlegen, die im gesamten CI/CD-Pipeline-File der Workflows verwendet werden.</p>

<h3>4.1 VM Öffentliche IP-Adressvariable</h3>
<p></p>
<p>Navigieren Sie in GitHub zu den Repository-Einstellungen und fügen Sie im Abschnitt "Geheimnisse und Variablen" in den Sicherheitseinstellungen die IP-Adresse im Aktionsbereich hinzu</p>
<img src="https://kmc-technologies.ltd/Blogs/CI-CD-Blog/IPConf.png"></img>
<p></p>
<h3>4.2 Variable für privaten SSH-Schlüssel</h3>
<img src="https://kmc-technologies.ltd/Blogs/CI-CD-Blog/SSH-Conf.png"></img>
<p></p>
<p>Machen Sie nun dasselbe für Ihren privaten SSH-Schlüssel, dieser kann in ./ssh/id_rsa gefunden werden</p>
<p></p>


<h2>5.1 CI/CD-Pipeline</h2>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`name: CI/CD Pipeline

on:
  push:
    branches:
      - master
`}</code></pre></div>
<p>Diese Einrichtung definiert den Beginn unseres CI/CD-Prozesses, bereit, auf Aktualisierungen des Master-Branches zu reagieren und so eine kontinuierliche Integration bei jedem Code-Push zu gewährleisten.</p>

<h3>5.2 Konfiguration für Build & Deployment</h3>
<p>Danach definieren wir einen <code>build-and-deploy</code>-Job, der auf der neuesten Windows-Umgebung ausgeführt werden soll. Dieser Job ist der Orchestrator, der die erforderlichen Schritte zur Umwandlung des Codes in einen deploybaren Zustand leitet.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`jobs:
  build-and-deploy:
    runs-on: windows-latest
`}</code></pre></div>
<p>Der Prozess beginnt mit der <code>checkout</code>-Aktion, die den Code des Repositories in den GitHub Actions Runner holt, sodass die neuesten Commits für die nachfolgenden Schritte bereit sind.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`steps:
- uses: actions/checkout@v2
`}</code></pre></div>

<h3>5.3 .NET einrichten & Projekt bauen</h3>
<p>Im Anschluss bereitet die <code>setup-dotnet</code>-Aktion die Umgebung mit der .NET-Version (<code>8.0.x</code>) vor, um sicherzustellen, dass der Runner die notwendige Laufzeit und das SDK zum Bauen der .NET-Anwendung hat.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Setup .NET
  uses: actions/setup-dotnet@v2
  with:
    dotnet-version: '8.0.x'
`}</code></pre></div>
<p>Als Nächstes kommt der Befehl <code>dotnet restore</code>, der die Abhängigkeiten des Projekts wiederherstellt, um sicherzustellen, dass alle notwendigen Pakete für den Bauprozess verfügbar sind.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Restore dependencies
  run: dotnet restore
`}</code></pre></div>
<p>Mit den Abhängigkeiten an ihrem Platz kompiliert <code>dotnet build</code> den Code zu ausführbaren Artefakten. Das Flag <code>--no-restore</code> zeigt an, dass die Wiederherstellung der Abhängigkeiten bereits behandelt wurde und der Prozess sich ausschließlich auf die Kompilierung konzentriert.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Build Application
  run: dotnet build --no-restore
`}</code></pre></div>
<p>Um die Integrität der Anwendung zu überprüfen, führt <code>dotnet test</code> die Testsuite aus und stellt sicher, dass der Code wie vorgesehen funktioniert.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Run Tests
  run: dotnet test --no-build
`}</code></pre></div>
<p>Nach Abschluss der Build- und Testphasen verpackt <code>dotnet publish</code> die Anwendung in ein deploybares Format und bereitet sie auf das Deployment vor.</p>
<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Publish Application
  run: dotnet publish AzWebAPI/AzWebAPI.csproj --configuration Release --output publish/AzWebAPI
`}</code></pre></div>

<h2>5.4 Dateiübertragung & Deployment auf die VM</h2>

<p>Vor dem Deployment wird ein SSH-Agent eingerichtet, der den sicher gespeicherten SSH-Privatschlüssel aus den GitHub Secrets verwendet, um eine sichere Verbindung zur Azure-VM herzustellen.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: SSH-Agent erstellen
  uses: webfactory/ssh-agent@v0.5.3
  with:
    ssh-private-key: $secrets.SSH_PRIVATE_KEY
`}</code></pre></div>

<p>Ein einfacher Befehl wird ausgeführt, um die Einsatzbereitschaft der SSH-Verbindung für das Deployment zu bestätigen, was die Vorbereitung für die letzten Deployment-Schritte markiert.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: SSH-Verbindung testen
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "echo Verbindung erfolgreich"
  shell: bash
`}</code></pre></div>

<p>Im weiteren Verlauf unserer CI/CD-Reise gehen wir in die Deployment-Phase über, in der die vorbereiteten Artefakte nahtlos in die Live-Umgebung überführt werden. Diese Phase ist durch kritische Schritte gekennzeichnet, die sicherstellen, dass die Anwendung nicht nur deployed, sondern auch gesichert und überprüft wird, was einen ganzheitlichen Ansatz für Continuous Delivery verkörpert.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Aktuelles wwwroot sichern
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY 'powershell -Command "& {$timestamp = Get-Date -Format ''yyyyMMddHHmmss''; $destination = \"C:/inetpub/wwwroot_backup_$timestamp\"; if (!(Test-Path $destination)) {New-Item -ItemType Directory -Path $destination}; Copy-Item -Path C:/inetpub/wwwroot/* -Destination $destination -Recurse -Force}"'
  shell: bash
`}</code></pre></div>

<p>Dieser Schritt stellt sicher, dass der aktuelle Zustand des `wwwroot`-Verzeichnisses durch Erstellen einer zeitgestempelten Sicherung erhalten bleibt. Diese Sicherheitsmaßnahme ermöglicht die Wiederherstellung in unvorhergesehenen Umständen und bietet eine Momentaufnahme der Anwendung vor dem neuen Deployment.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Aktuellen Inhalt von wwwroot löschen und sicherstellen, dass das Verzeichnis existiert
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Get-ChildItem -Path C:/inetpub/wwwroot -Exclude wwwroot_backup_* | Remove-Item -Recurse -Force; if (-not (Test-Path -Path C:/inetpub/wwwroot)) { New-Item -Path C:/inetpub/wwwroot -ItemType Directory }\""
  shell: bash
`}</code></pre></div>

<p>Nach der Sicherung wird das `wwwroot`-Verzeichnis von seinem Inhalt befreit, um eine makellose Umgebung für das neue Deployment zu gewährleisten. Dieser Schritt vermeidet sorgfältig Störungen der Sicherungsverzeichnisse und erhält die Integrität der Backups.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Dateien im Veröffentlichungsverzeichnis auflisten
  run: ls -l publish/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>Vor dem Fortfahren mit der Dateiübertragung bietet eine Auflistung der Dateien im Veröffentlichungsverzeichnis einen Einblick in die für das Deployment bereiten Artefakte und bietet einen Kontrollpunkt zur Überprüfung.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Übertragung der veröffentlichten Dateien auf die VM
  run: |
    scp -o StrictHostKeyChecking=no -r ./publish/AzWebAPI azureuser@$secrets.SSH_PRIVATE_KEY:C:/inetpub/wwwroot/AzWebAPI
  shell: bash
`}</code></pre></div>

<p>Die vorbereiteten Artefakte werden dann sicher auf die Azure-VM übertragen und im `wwwroot`-Verzeichnis abgelegt. Dieser Schritt verwendet das Secure Copy Protocol (SCP), um die Integrität und Sicherheit der Dateiübertragung zu gewährleisten.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Neue WebAPI in wwwroot kopieren
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"if (Test-Path -Path C:/inetpub/wwwroot/AzWebAPI) { Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | ForEach-Object { Copy-Item -Path $_.FullName -Destination (Join-Path C:/inetpub/wwwroot $_.Name) -Force } } else { Write-Output 'C:/inetpub/wwwroot/AzWebAPI existiert nicht, Kopieren wird übersprungen.' }\""
  shell: bash
`}</code></pre></div>

<p>Nach der erfolgreichen Übertragung werden die Inhalte des Verzeichnisses `AzWebAPI` sorgfältig in das `wwwroot`-Verzeichnis integriert, um sicherzustellen, dass die neue Version der Anwendung zugänglich ist.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: Inhalte von AzWebAPI nach wwwroot verschieben
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Get-ChildItem -Path C:/inetpub/wwwroot/AzWebAPI -Recurse | Move-Item -Destination C:/inetpub/wwwroot\""
  shell: bash
`}</code></pre></div>

<p>Dieser Schritt stellt weiterhin sicher, dass die Inhalte von `AzWebAPI` nicht nur kopiert, sondern auch an die Wurzel des `wwwroot`-Verzeichnisses verschoben werden, entsprechend der gewünschten Struktur für die Live-Anwendung.</p>

<div class="code-block"><pre class="language-yaml"><code class="language-yaml">{`- name: IIS neu starten
  run: |
    ssh -o StrictHostKeyChecking=no azureuser@$secrets.SSH_PRIVATE_KEY "powershell -Command \"Restart-Service -Name W3SVC -Force\""
  shell: bash
`}</code></pre></div>

<p>Der Abschluss der Deployment-Phase wird durch den Neustart der Internetinformationsdienste (IIS) gekennzeichnet, um sicherzustellen, dass alle Updates geladen sind und die Anwendung mit ihren neuesten Funktionen bereitgestellt wird. Dieser letzte Schritt haucht der bereitgestellten Anwendung Leben ein und macht sie in ihrer aktuellsten Form für die Benutzer zugänglich.</p>

<p>Durch diese sorgfältig ausgearbeiteten Schritte umfasst der GitHub Actions-Workflow das Wesen der kontinuierlichen Bereitstellung und stellt sicher, dass jeder Aspekt des Deployments mit Sorgfalt behandelt wird, von der Sicherung des aktuellen Zustands bis zur nahtlosen Integration der neuen Version in die Live-Umgebung. Dieser Ansatz vereinfacht nicht nur den Deployment-Prozess, sondern integriert auch Sicherheitsmaßnahmen und Verifikationen, um die höchste Zuverlässigkeit und Integrität bei der Bereitstellung von Updates für die Live-Anwendung zu gewährleisten.</p>

<p>In einem Webbrowser, wenn Sie nun zu der öffentlichen IP-Adresse Ihrer Azure-VM navigieren, gefolgt vom Standard-.NET-Web-API-Wettervorhersage-Endpunkt (/WeatherForecast), sehen Sie die Boilerplate-JSON-Antwort.</p>

<p>Und damit schließen wir diesen Teil unserer Reise durch die Automatisierungslandschaft ab. Aber seien Sie versichert, das Abenteuer endet hier nicht. In einem kommenden Beitrag werde ich in die Welten der Azure Virtual Machines oder Docker-Container eintauchen, um eine maßgeschneiderte Iteration von GitHub Actions zu erstellen, die auf unsere einzigartigen Bedürfnisse zugeschnitten ist. Behalten Sie also diesen Raum im Auge und bleiben Sie dran für weitere Einblicke und Erkundungen in der sich ständig weiterentwickelnden Welt von DevOps. Ihre fortgesetzte Beteiligung beflügelt unsere Erkundung dieser technologischen Grenzen. Bis zum nächsten Mal, frohes Programmieren!</p>




















</div>)
    }

];

export default blogsData;
