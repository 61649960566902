import React from "react";
import './HomepageBanner.css'
import { TypeAnimation } from 'react-type-animation';

function Banner() {
  return (
    <div className="banner">
      <div className="banner__text">
      <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Welcome!',
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        'Willkommen!',
        1000,
        '',
        5000
      ]}
      wrapper="span"
      speed={50}
      // style={{ fontSize: '2em', display: 'inline-block' }}
      className="banner__title"
      repeat={Infinity}
    />
      </div>
    
    
    </div>
  );
}

export default Banner;
