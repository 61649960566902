import React from "react";
import "./Navbar.css"
import { TypeAnimation } from 'react-type-animation';

function Navbar() {
    return (
      <nav className="navbar">
        <div className="navbar__container">
          <a href="/" className="navbar__logo">
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "Kieron's Kernel",
              1000
            ]}
            wrapper="span"
            speed={75}
            cursor={false}
            // style={{ fontSize: '2em', display: 'inline-block' }}
            className="navbar__logo"
            // repeat={Infinity}
          />
          </a>
          <ul className="navbar__menu">
            <li className="navbar__item">
              <a href="https://linkedin.com/in/kieron-cairns-2567bb107" className="navbar__link">
              <img className="navbar__linkedin__img"src="./LI-In-Bug.png"></img>
              </a>
            </li>
            <li className="navbar__item">
              <a href="https://twitter.com/KieronDeveloper" className="navbar__link">
                <img className="navbar__twitter__img" src="./twitter-logo-blue.png"></img>
              </a>
            </li>
            <li className="navbar__item">
              <a href="https://kieron-developer.uk/" className="navbar__link">
              <img className="navbar__link__contact__img" src="./business-contact-128.png"></img>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
  
  export default Navbar;