import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Prism from 'prismjs';
import 'prismjs/themes/prism.css'; 
import './BlogPost.css'
import { TypeAnimation } from 'react-type-animation';



function BlogPost({initialContent, title, img ,titleDe, content, contentDe, date}) {
  let { id } = useParams();

  const [isToggled, setIsToggled] = useState(false); 
  const [language, setLanguage] = useState('EN');

  const toggleSwitch = () => {
    setIsToggled(!isToggled); 
  }

  useEffect(() => {
    Prism.highlightAll()
  }, []);

  const handleChange = (event) => {
    console.log(event.target.value)
    setLanguage(event.target.value);
  }

  const imagePath = require(`../../assets/${img}`);

  return (
    <div className="content-item">

        <div className="content-item">
          <img className="content-item-title-image" src={imagePath}></img>
          </div>

        <div className="date-language-section">
        <p className="date">{date}</p>
            <select value={language} onChange={handleChange}>
              <option value="EN">EN </option>🇬
              <option value="DE">DE</option>
            </select>

            <img className={language === 'EN' ? 'GB-Select' : 'DE-Select'} src={language === 'EN' ? '/GB-Flag.png' : '/DE-Flag.png'} alt="Localized content" />

        </div>
        {language === 'EN' ? <div className="content-item">
        <h1 class='h1-animated'>
        <TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          title,
          1000
        ]}
        wrapper="span"
        speed={85}
        cursor={false}
        // style={{ fontSize: '2em', display: 'inline-block' }}
      />
      </h1>
        </div> : <div className="content-item">
        <h1 className="h1-animated">
        {titleDe}
      </h1></div>}


       
        {language === 'EN' ? content : contentDe}
    </div>
    
  );
}

export default BlogPost;
