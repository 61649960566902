import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { CardContext } from "../Utilities/CardContext";
import './MainSection.css';

function MainSection() {
  const { isLessThanFour, blogs } = useContext(CardContext);
  const [language, setLanguage] = useState('EN');

  const handleChange = (event) => {
    setLanguage(event.target.value);
    };
    
    return (
    <div className="main-section">
    <div className="main-section__container">
    <div className='main-section__cards'>
    <div className="language-selector">
    <select value={language} onChange={handleChange}>
    <option value="EN">EN</option>
    <option value="DE">DE</option>
    </select>
    <img className={language === 'EN' ? 'GB-Select' : 'DE-Select'} src={language === 'EN' ? '/GB-Flag.png' : '/DE-Flag.png'} alt="Localized content" />
    </div>
    {blogs.map((blog, index) => {
        const imagePath = require(`../../assets/${blog.img}`);  // Dynamically import the image based on the blog data
        return (
          <Link to={blog.link} className="main-section__card" key={index}>
            <div className="main-section__card-content">
              <img src={imagePath} alt={blog.title} className="main-section__card-image" />
              <h3>{language === 'EN' ? blog.title : blog.titleDe}</h3>
              <p>{language === 'EN' ? blog.desc : blog.descDe}</p>
            </div>
            <h4 className="main-section__card-date">{language === 'EN' ? blog.date : blog.dateDe}</h4>
          </Link>
        );
      })}
    </div>
  </div>
</div>
    );
  }

  export default MainSection;