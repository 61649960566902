import React, {useContext} from "react";
import "./FooterSection.css"
import { CardContext } from "../Utilities/CardContext";

const Footer = () => {

    const { isLessThanFour } = useContext(CardContext);

    return (
      <div className='footer'>
      <div className="footer__container">
        <ul className="footer__menu">
            <li className="footer__item">
              <a href="https://linkedin.com/in/kieron-cairns-2567bb107" className="">
              {/* <img className="navbar__linkedin__img"src="/LI-In-Bug.png"></img> */}
              <p>Linkedin</p>

              </a>
            </li>
            <li className="footer__item">
              <a href="https://twitter.com/KieronDeveloper" className="navbar__link">
              {/* <img className="navbar__twitter__img" src="/twitter-logo-blue.png"></img> */}
              <p>Twitter</p>
              </a>
            </li>
            <li className="footer__item">
              <a href="https://kieron-developer.uk/" className="footer__link">
              {/* <img className="navbar__link__contact__img" src="/business-contact-128.png"></img> */}
              <p>CV / Contact</p>
              </a>
            </li>
          </ul>
      </div>
    </div>
    );
  }
  
  export default Footer;