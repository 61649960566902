import React, { createContext, useState, useEffect } from "react";
import blogsData from './blogsData.js';

export const CardContext = createContext();

export const CardProvider = ({ children }) => {
  const [blogs, setBlogs] = useState(blogsData);
  const [isLessThanFour, setIsLessThanFour] = useState(false);

  useEffect(() => {
    setIsLessThanFour(blogs.length <= 3);
  }, [blogs]);

  return (
    <CardContext.Provider value={{ isLessThanFour, blogs }}>
      {children}
    </CardContext.Provider>
  );
};
