import React, { useEffect } from 'react';
import "./BlogNavbar.css"
import { TypeAnimation } from 'react-type-animation';

const BlogNavbar = () => {
  
  return (
    
    <nav className='blog-navbar'>
      <div className="blog__navbar__container">
      <a href="/" className="blog--navbar--logo">
      <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        "Kieron's Kernel",
        1000
      ]}
      wrapper="span"
      speed={25}
      cursor={false}
      // style={{ fontSize: '2em', display: 'inline-block' }}
      className="blog--navbar--logo"
      // repeat={Infinity}
    />
      </a>
      {/* <div className='blog-navbar-a-tags'> */}
      <ul className="navbar__menu">
            <li className="blog__navbar__item">
      <a href="/" className="navbar-link">
      <img className="blog__navbar__link__img"src="/LI-In-Bug.png"></img>
      </a>
      </li>
      <li className="blog__navbar__item">
      <a href="/about" className="navbar-link">
      <img className="blog__navbar__link__img" src="/twitter-logo-blue.png"></img>
      </a>
      </li>
      <li className="blog__navbar__item">
      <a href="/contact" className="navbar-link">
      <img className="blog__navbar__link__contact__img" src="/business-contact-128.png"></img>
      </a>
      </li>
      </ul>
      </div>
      {/* </div> */}
    </nav>
  );
}

export default BlogNavbar;
