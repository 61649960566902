import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams } from 'react-router-dom';
import { CardContext } from './CardContext';
import BlogPost from '../BlogPost/BlogPost';

function BlogPostWithContent() {
    let { id } = useParams(); // Get the blog id from the current route
    const { blogs } = useContext(CardContext); // Get the blogs array from your context
  
    // Find the correct blog post
    const blog = blogs.find((blog) => blog.link === "/blog/" + id);
    if (!blog) {
      return <div>Blog post not found</div>; // You can render a 404 page or something else here
    }
  
    // Pass the blog content as a prop to the BlogPost component
    return <BlogPost initialContent={blog.initialContent} title={blog.title} img={blog.img} titleDe={blog.titleDe} content={blog.content} contentDe={blog.contentDe} date={blog.date} />;
  }
  
  export default BlogPostWithContent;