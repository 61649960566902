import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import BlogNavbar from './Components/Navbar/BlogNavbar';
import MainSection from './Components/MainSection/MainSection';
import Banner from './Components/BannerSection/HomepageBanner';
import Footer from './Components/FooterSection/FooterSection';
import BlogPost from './Components/BlogPost/BlogPost';
import { CardProvider } from './Components/Utilities/CardContext';
import "./App.css"
import BlogPostWithContent from './Components/Utilities/BlogPostWithContent';

function Layout() {
  const location = useLocation();
  const [navBarHeight, setNavBarHeight] = useState("5vh");

  useEffect(() => {
    setNavBarHeight("30vh"); // Change "3vh" to the height you prefer when scrolled up

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      console.log("Current Scroll Y")
      console.log(currentScrollY)
      if (currentScrollY < 50) { // Change 50 to the value you prefer
        setNavBarHeight("30vh"); // Change "3vh" to the height you prefer when scrolled up
      } else {
        setNavBarHeight("0vh"); // This is the original height
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    
    // Clean up function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  

  const renderNavbar = () => {
    if (location.pathname.includes("/blog/")) {
      return <BlogNavbar />
    } else {
      return <Navbar />
    }
  }

  return (
    <>
<div className='navbar-over-scroll' style={{ height: navBarHeight }}></div>
      {renderNavbar()}
      {location.pathname.includes("/blog/") ? null : <Banner />}
      <CardProvider>
      <Routes>
        <Route path="/" element={<MainSection />} />
        {/* <Route path="/blog/:id" element={<BlogPost content={blogs[id].content} />} /> */}
        <Route path='/blog/:id' element={<BlogPostWithContent />} />
      </Routes>
      <Footer />
      </CardProvider>
    </>
  );
}

function App() {

  

  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
